import { defineStore } from 'pinia'
import { useUserStore } from './user'
import { isBeta } from '@/utils/utils'

interface WalletState {
  address: string
  balance: string
}

const prefix = isBeta ? 'tNULS' : 'NULS'
export const useWalletStore = defineStore('wallet', {
  state: (): WalletState => ({
    address: '',
    balance: '0'
  }),
  getters: {
    isWrongChaing(state) {
      return !state.address.startsWith(prefix)
    }
  },
  actions: {
    changeAccount(val: string) {
      if (val.startsWith(prefix)) {
        this.address = val
      } else {
        this.address = ''
        const userStore = useUserStore()
        userStore.tokenWithBalances = userStore.tokens
      }
    }
  }
})

/* export const useWalletStore = defineStore('wallet', () => {
  const address = ref('')
  const balance = ref('0')
  const isConnected = ref(false)

  function connect() {
    address.value = 'Nse7gF6Xg3hj9KLq2mY5k8P9Rm4xZ'
    balance.value = '335.711'
    isConnected.value = true
  }

  function disconnect() {
    address.value = ''
    balance.value = '0'
    isConnected.value = false
  }

  return {
    address,
    balance,
    isConnected,
    connect,
    disconnect
  }
}) */
