<template>
  <div class="space-y-4">
    <div v-if="lps.length === 0" class="text-center py-12">
      <p class="text-gray-400">No liquidity found</p>
    </div>

    <div v-else class="space-y-4">
      <PoolCard
        v-for="lp in lps"
        :key="lp.lpToken.address"
        :lpInfo="lp"
        @add="$emit('add', lp)"
        @remove="$emit('remove', lp)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import PoolCard from './PoolCard.vue'
import useUserLPs, { ILP } from './hooks/useUserLPs';

defineEmits<{
  (e: 'add', lpInfo: ILP): void
  (e: 'remove', lpInfo: ILP): void
}>()

const { lps } = useUserLPs()
</script>