<template>
  <div
    class="bg-gray-800/50 backdrop-blur-sm rounded-2xl p-4 border border-gray-700/50 hover:border-gray-600/50 transition-all duration-200 md:p-6">
    <!-- Header -->
    <div class="text-center mb-6">
      <div
        class="bg-primary/10 text-primary text-sm py-2 px-4 rounded-lg inline-block mb-4">
        Total Locked: {{ totalLocked.toLocaleString() }} {{ token?.symbol }}
      </div>
      <h3 class="text-xl font-bold text-white mb-2">{{ title }}</h3>
      <p class="text-gray-400 text-sm">{{ description }}</p>
    </div>

    <!-- Stake Input -->
    <div class="space-y-3 mb-6">
      <div class="flex justify-between items-center">
        <span class="text-gray-400 text-sm"
          >Balance: {{ token?.balance || '-' }}</span
        >
        <button
          class="text-primary hover:text-primary-dark text-sm font-medium transition-colors"
          @click="stakeValue = token?.balance">
          Max
        </button>
      </div>

      <div class="relative">
        <input
          type="text"
          :value="stakeValue"
          @input="changeStakeValue"
          :placeholder="
            minStake ? `Minimum to stake: ${minStake} ${token?.symbol}` : '0.00'
          "
          class="w-full pl-4 pr-[90px] py-3 rounded-xl bg-gray-700/50 text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-primary/50" />
        <button
          :class="[
            'absolute right-2 top-1/2 -translate-y-1/2 py-2 rounded-lg bg-primary text-white text-sm font-medium w-[70px] text-center',
            {
              'opacity-50 cursor-not-allowed': disabledStake,
              'hover:bg-primary-dark transition-colors': !disabledStake
            }
          ]"
          :disabled="disabledStake"
          @click="handleStake">
          {{ tokenNeedApprove ? 'Approve' : 'Stake' }}
        </button>
      </div>
    </div>

    <!-- Action Buttons -->
    <div class="flex flex-wrap gap-2 mb-6">
      <template
        v-if="
          stakeType === STAKE_TYPE.STAKE_NSWAP ||
          stakeType === STAKE_TYPE.STAKE_NULS_EARN_NULS
        ">
        <button class="action-button py-1.5 text-xs" @click="emit('compound')">
          COMPOUND
        </button>
        <button
          class="action-button py-1.5 text-xs"
          @click="emit('unstakeAll')">
          UNSTAKE ALL
        </button>
      </template>
      <template v-else-if="stakeType === STAKE_TYPE.STAKE_NULS_EARN_NSWAP">
        <button class="action-button py-1.5 text-xs" @click="emit('getReward')">
          GET REWARD
        </button>
        <button
          class="action-button py-1.5 text-xs"
          @click="emit('unstake', stakeValue)">
          UNSTAKE
        </button>
      </template>
      <template v-else>
        <button class="action-button py-1.5 text-xs" @click="emit('harvest')">
          HARVEST
        </button>
        <button class="action-button py-1.5 text-xs" @click="emit('getReward')">
          GET REWARD
        </button>
        <button
          class="action-button py-1.5 text-xs"
          @click="emit('unstakeAll')">
          UNSTAKE ALL
        </button>
      </template>
    </div>

    <!-- Stats -->
    <div class="bg-gray-900/30 rounded-xl p-4">
      <h4 class="text-lg font-medium text-white mb-4">Your Stats</h4>
      <div class="space-y-3">
        <slot name="stats"></slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { useTokenAllowance } from '../pools/hooks/useAllowance'
import { createRegex, timesDecimals } from '@/utils/utils'
import { IToken } from '@/stores/user'
import { LoadingService } from '@/components/Loading/loading-directives'
import { STAKE_TYPE } from './hooks/useStakeData'

const props = defineProps<{
  contract?: string
  stakeType: STAKE_TYPE
  title: string
  description: string
  totalLocked: string
  minStake?: string
  token?: IToken
}>()

const emit = defineEmits<{
  (e: 'stake', value: string): void
  (e: 'compound'): void
  (e: 'unstakeAll'): void
  (e: 'getReward'): void
  (e: 'unstake', value: string): void
  (e: 'harvest'): void
}>()

const stakeValue = ref('')
const stakeToken = computed(() => {
  return props.token!
})
const { allowanceLoading, tokenNeedApprove, approveAllowance } =
  useTokenAllowance(stakeToken, stakeValue, props.contract || '')

let stakeValueLocal = stakeValue.value
const changeStakeValue = (event: Event) => {
  const target = event.target as HTMLInputElement
  const { value } = target
  const decimals = props.token?.decimals || 8
  const reg = createRegex(decimals)
  if (reg.test(value) || !value) {
    stakeValue.value = value
    stakeValueLocal = value
  } else {
    target.value = stakeValueLocal
  }
}

const disabledStake = computed(() => {
  if (allowanceLoading.value) {
    return true
  } else if (tokenNeedApprove.value) {
    return false
  } else if (!Number(stakeValue.value)) {
    return true
  } else if (Number(props.token?.balance) - Number(stakeValue.value) < 0) {
    return true
  } else if (
    props.minStake &&
    Number(stakeValue.value) < Number(props.minStake)
  ) {
    return true
  } else {
    return false
  }
})

const handleStake = async () => {
  if (tokenNeedApprove.value) {
    LoadingService.show()
    await approveAllowance(
      stakeToken.value.address,
      timesDecimals(stakeValue.value, stakeToken.value.decimals)
    )
    LoadingService.hide()
  } else {
    emit('stake', stakeValue.value)
  }
}
</script>

<style scoped>
.action-button {
  @apply flex-1 px-3 rounded-lg border border-primary text-primary hover:bg-primary/10 transition-colors font-medium;
}

.stat-row {
  @apply flex justify-between items-center;
}

.stat-row > span:first-child {
  @apply text-gray-400;
}

.stat-row > span:last-child {
  @apply text-white;
}
</style>
