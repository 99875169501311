<template>
  <div class="min-h-screen bg-gradient-main pb-16 lg:pb-0">
    <Navbar />
    <main class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
      <router-view></router-view>
    </main>
    <Footer />
    <MobileNav />
  </div>
</template>

<script setup lang="ts">
import Navbar from './components/layout/Navbar.vue'
import Footer from './components/layout/Footer.vue'
import MobileNav from './components/layout/MobileNav.vue'
import useBlockData from './hooks/useBlockData'

useBlockData()
</script>
