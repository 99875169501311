import http from '..'
import { isBeta } from '@/utils/utils'
import { IToken } from '@/stores/user'

/* const naboxUrl = isBeta
  ? '/asset/query'
  : 'https://api.v2.nabox.io/nabox-api/asset/query' */

interface SearchAsset {
  chainId: number
  assetId: number
  contractAddress: string
  symbol: string
  icon: string
  decimals: number
}
export async function queryAsset(chain: string, query: string) {
  const reuslt = await http.post<SearchAsset[]>({
    url: '/asset/query',
    data: {
      language: 'CHS',
      chain,
      searchKey: query
    }
  })
  const supportChainId = isBeta ? [2, 5] : [1, 9]
  if (reuslt.code === 1000) {
    return reuslt.data.filter(v => supportChainId.includes(v.chainId))
  }
  return []
}

export interface ITokens {
  baseTokens: IToken[]
  defaultTokens: IToken[]
}
export async function getTokens() {
  const reuslt = await http.get<ITokens>({
    url: '/nuls/token/config'
  })
  if (reuslt.code === 1000) {
    return reuslt.data
  }
  return {
    baseTokens: [],
    defaultTokens: []
  }
}
