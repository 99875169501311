import { Ref } from 'vue'
import useToast from '@/hooks/useToast'
import { useWalletStore } from '@/stores/wallet'
import { useUserStore } from '@/stores/user'
import {
  swapExactNulsForTokens,
  swapNulsForExactTokens,
  swapExactNulsForWAsset,
  swapNulsForExactWAsset,
  swapExactTokensForNuls,
  swapTokensForExactNuls,
  swapExactTokensForTokens,
  swapTokensForExactTokens,
  swapExactTokensForWAsset,
  swapTokensForExactWAsset,
  swapExactWAssetForWAsset,
  swapWAssetForExactWAsset,
  swapExactWAssetForNuls,
  swapWAssetForExactNuls,
  swapExactWAssetForTokens,
  swapWAssetForExactTokens,
  calcWAssetAddress
} from '@/utils/nuls'
import { timesDecimals } from '@/utils/utils'
import { IToken } from '@/stores/user'

import { WNULS, NULSKey } from '@/constant/contract'

export default function useTrade(
  tokenA: Ref<IToken>,
  tokenB: Ref<IToken>,
  amountA: Ref<string>,
  amountB: Ref<string>
) {
  const walletStore = useWalletStore()
  const userStore = useUserStore()
  const { toastSuccess, toastError } = useToast()

  const swap = async (
    isExactIn: boolean,
    receiveOrSoldAmount: string,
    route?: string[]
  ) => {
    if (!route?.length) {
      toastError('Invalid route')
      return
    }
    const {
      assetKey: assetKeyA,
      address: addressA,
      decimals: decimalsA,
      symbol: symbolA
    } = tokenA.value
    const {
      assetKey: assetKeyB,
      address: addressB,
      decimals: decimalsB,
      symbol: symbolB
    } = tokenB.value
    const tokenAIsNULS = assetKeyA === NULSKey
    const tokenBIsNULS = assetKeyB === NULSKey
    const tokenAIsWasset = !addressA && !tokenAIsNULS
    const tokenBIsWasset = !addressB && !tokenBIsNULS
    const tokenAAmount = timesDecimals(amountA.value, decimalsA)
    const tokenBAmount = timesDecimals(amountB.value, decimalsB)
    const _receiveOrSoldAmount = timesDecimals(
      receiveOrSoldAmount,
      isExactIn ? decimalsB : decimalsA
    )
    const _route = route.map(v => {
      if (v === NULSKey) {
        return WNULS
      } else if (v.includes('-')) {
        return calcWAssetAddress(v)
      } else {
        return v
      }
    })
    const owner = walletStore.address
    let hash
    const tx = {
      summary: `Swap ${amountA.value} ${symbolA} for ${amountB.value} ${symbolB}`,
      hash: '',
      time: new Date().getTime() + '',
      status: 0 as const
    }
    try {
      if (isExactIn) {
        if (tokenAIsNULS) {
          if (tokenBIsWasset) {
            // nuls-wasset
            hash = await swapExactNulsForWAsset(
              owner,
              amountA.value,
              _receiveOrSoldAmount,
              assetKeyB,
              _route
            )
          } else {
            // nuls-token
            hash = await swapExactNulsForTokens(
              owner,
              amountA.value,
              _receiveOrSoldAmount,
              _route
            )
          }
        } else if (tokenBIsNULS) {
          if (tokenAIsWasset) {
            // wasset-nuls
            hash = await swapExactWAssetForNuls(
              owner,
              assetKeyA,
              amountA.value,
              _receiveOrSoldAmount,
              _route
            )
          } else {
            // token-nuls
            hash = await swapExactTokensForNuls(
              owner,
              tokenAAmount,
              _receiveOrSoldAmount,
              _route
            )
          }
        } else if (tokenAIsWasset) {
          if (tokenBIsWasset) {
            // wasset-wasset
            hash = await swapExactWAssetForWAsset(
              owner,
              assetKeyA,
              assetKeyB,
              amountA.value,
              _receiveOrSoldAmount,
              _route
            )
          } else {
            // wasset-token
            hash = await swapExactWAssetForTokens(
              owner,
              assetKeyA,
              amountA.value,
              _receiveOrSoldAmount,
              _route
            )
          }
        } else if (tokenBIsWasset) {
          // token-wasset
          hash = await swapExactTokensForWAsset(
            owner,
            assetKeyB,
            tokenAAmount,
            _receiveOrSoldAmount,
            _route
          )
        } else {
          // token-token
          hash = await swapExactTokensForTokens(
            owner,
            tokenAAmount,
            _receiveOrSoldAmount,
            _route
          )
        }
      } else {
        // -----------------reverse-----------------
        if (tokenAIsNULS) {
          if (tokenBIsWasset) {
            // nuls-exact wasset
            hash = await swapNulsForExactWAsset(
              owner,
              assetKeyB,
              receiveOrSoldAmount,
              tokenBAmount,
              _route
            )
          } else {
            // nuls-exact token
            hash = await swapNulsForExactTokens(
              owner,
              receiveOrSoldAmount,
              tokenBAmount,
              _route
            )
          }
        } else if (tokenBIsNULS) {
          if (tokenAIsWasset) {
            // wasset-exact nuls
            hash = await swapWAssetForExactNuls(
              owner,
              assetKeyA,
              receiveOrSoldAmount,
              tokenBAmount,
              _route
            )
          } else {
            // token-exact nuls
            hash = await swapTokensForExactNuls(
              owner,
              tokenBAmount,
              _receiveOrSoldAmount,
              _route
            )
          }
        } else if (tokenAIsWasset) {
          if (tokenBIsWasset) {
            // wasset-exact wasset
            hash = await swapWAssetForExactWAsset(
              owner,
              assetKeyA,
              assetKeyB,
              receiveOrSoldAmount,
              tokenBAmount,
              _route
            )
          } else {
            // wasset-exact token
            hash = await swapWAssetForExactTokens(
              owner,
              assetKeyA,
              receiveOrSoldAmount,
              tokenBAmount,
              _route
            )
          }
        } else if (tokenBIsWasset) {
          // token-exact wasset
          hash = await swapTokensForExactWAsset(
            owner,
            assetKeyB,
            _receiveOrSoldAmount,
            tokenBAmount,
            _route
          )
        } else {
          // token-exact token
          hash = await swapTokensForExactTokens(
            owner,
            _receiveOrSoldAmount,
            tokenBAmount,
            _route
          )
        }
      }
      if (hash) {
        toastSuccess('Success')
        tx.hash = hash
        userStore.addTx(tx)
        return true
      }
    } catch (e) {
      toastError(e)
    }
    return false
  }
  return {
    swap
  }
}
