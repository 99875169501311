<template>
  <component :is="childComponent" v-bind="$attrs" @click="handleClick" />
</template>

<script setup lang="ts">
import { h } from 'vue'
import copy from 'copy-to-clipboard'
import useToast from '@/hooks/useToast';

const props = withDefaults(
  defineProps<{
    text: string
    successText?: string
    onCopy?: (text: string, result: boolean) => void
    options?: {
      // copy-to-clipboard
      debug?: boolean
      message?: string
      format?: string
    }
  }>(),
  {}
)

const slots = defineSlots<{
  default(): any
}>()

const { toastSuccess } = useToast()

const childComponent = slots.default ? slots.default()[0] : h('span')

const handleClick = (event: MouseEvent) => {
  const result = copy(props.text, props.options)

  if (props.onCopy) {
    props.onCopy(props.text, result)
  } else {
    toastSuccess(props.successText || 'Copied')
  }

  if (
    childComponent.props &&
    typeof childComponent.props.onClick === 'function'
  ) {
    childComponent.props.onClick(event)
  }
}
</script>
