import { computed, Ref, ref, onMounted } from 'vue'
import { useAllCommonPairs, useTradeExactIn, useTradeExactOut } from './Trade'
import {
  computeSlippageAdjustedAmounts as computeSlippageAdjustedAmountsForV2Trade,
  computeTradePriceBreakdown as computeTradePriceBreakdownForV2Trade,
  warningSeverity
} from '@/utils/exchange'
import { getPlatformFee } from '@/utils/nuls'
import { ONE_BIPS } from '@/constant/exchange'
import { Division, fixNumber, Plus } from '@/utils/utils'
import { IToken, useUserStore } from '@/stores/user'

export default function useTradeInfo(
  tokenA: Ref<IToken>,
  tokenB: Ref<IToken>,
  amountA: Ref<string>,
  amountB: Ref<string>
) {
  const userStore = useUserStore()

  const platformFee = ref('')

  onMounted(() => {
    getPlatformFee().then(res => (platformFee.value = res))
  })

  const isExactIn = ref(true)

  const allowedPairs = useAllCommonPairs(tokenA, tokenB)

  const bestTradeExactIn = useTradeExactIn(
    allowedPairs,
    tokenA,
    tokenB,
    amountA,
    platformFee
  )
  const bestTradeExactOut = useTradeExactOut(
    allowedPairs,
    tokenA,
    tokenB,
    amountB
  )

  const trade = computed(() => {
    return isExactIn.value ? bestTradeExactIn.value : bestTradeExactOut.value
  })

  const tradeInfo = computed(() => {
    const v2Trade = trade.value
    if (v2Trade) {
      const { priceImpactWithoutFee, realizedLPFee } =
        computeTradePriceBreakdownForV2Trade(v2Trade) // price impact
      return {
        tradeType: v2Trade.tradeType,
        route: v2Trade.route,
        inputAmount: v2Trade.inputAmount,
        outputAmount: v2Trade.outputAmount,
        slippageAdjustedAmounts: computeSlippageAdjustedAmountsForV2Trade(
          v2Trade,
          Number(userStore.slippageTolerance) * 100
        ), // min received
        executionPrice: v2Trade.executionPrice,
        priceImpactWithoutFee,
        realizedLPFee // 流动性提供者费用
        // inputError: swapInputError
      }
    }
    return null
  })

  const showInvertedPrice = ref(false)
  const tradePrice = computed(() => {
    if (!tradeInfo.value || !amountA.value || !amountB.value) return '-'
    // x A = y B
    // !showInvertedPrice -> 1 B = x/y * A
    // showInvertedPrice -> 1 A = y / x * B
    const baseSymbol = !showInvertedPrice.value
      ? tokenA.value.symbol
      : tokenB.value.symbol
    const baseValue = !showInvertedPrice.value ? amountA.value : amountB.value
    const quoteSymbol = showInvertedPrice.value
      ? tokenA.value.symbol
      : tokenB.value.symbol
    const quoteValue = showInvertedPrice.value ? amountA.value : amountB.value

    const price = fixNumber(Division(baseValue, quoteValue).toFixed(), 6)

    return `1 ${quoteSymbol} ≈ ${price} ${baseSymbol}`
  })

  const receiveOrSoldAmount = computed(() => {
    if (!tradeInfo.value) return '-'
    const { slippageAdjustedAmounts } = tradeInfo.value
    // const extraFee = Plus(100, platformFee.value).toFixed()
    const extraFee = Plus(10000, platformFee.value).toFixed()
    return isExactIn.value
      ? slippageAdjustedAmounts.B
        ? `${slippageAdjustedAmounts.B.toSignificant(6)}`
        : '-'
      : slippageAdjustedAmounts.A
        ? `${slippageAdjustedAmounts.A.multiply(extraFee).divide(10000).toSignificant(6)}`
        : '-'
  })

  const priceImpactInfo = computed(() => {
    const priceImpact = tradeInfo.value?.priceImpactWithoutFee
    const severity = warningSeverity(priceImpact)
    const color =
      severity === 3 || severity === 4
        ? 'text-[#fb4078]'
        : severity === 2
          ? 'text-[#FFB237]'
          : severity === 1
            ? ''
            : 'text-green-400'
    const value = priceImpact
      ? priceImpact.lessThan(ONE_BIPS)
        ? '<0.01%'
        : `${priceImpact.toFixed(2)}%`
      : '-'
    return {
      color,
      value
    }
  })

  return {
    isExactIn,
    trade,
    tradeInfo,
    platformFee,
    showInvertedPrice,
    tradePrice,
    receiveOrSoldAmount,
    priceImpactInfo
  }
}
