// import { getAddress } from '@ethersproject/address'
// import invariant from 'tiny-invariant'
// import warning from 'tiny-warning'

// warns if addresses are not checksummed
// eslint-disable-next-line consistent-return
export function validateAndParseAddress(address: string): string {
  return address
  /* try {
    const checksummedAddress = getAddress(address)
    // console.log('================>',checksummedAddress,address)
    warning(address === checksummedAddress, `${checksummedAddress} : ${address} is not checksummed.`)
    return checksummedAddress
  } catch (error) {
    invariant(false, `${address} is not a valid address.`)
  } */
}
