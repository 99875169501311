import { defineStore } from 'pinia'
import { useWalletStore } from './wallet'
import storage from '@/utils/storage'
import { divisionDecimals } from '@/utils/utils'
import { calcWAssetAddress } from '@/utils/nuls'
import { NULSKey, NSWAPKey } from '@/constant/contract'
import { getNAssetsBalance, getTxs, getLatestHeight } from '@/service/api'
import { getTokens, ITokens } from '@/service/api/callNabox'

interface UserState {
  blockHeight: string
  tokens: IToken[]
  tokenWithBalances: IToken[]
  baseTokens: IToken[]
  allTxs: { [address: string]: ITx[] }
  slippageTolerance: string
  refreshSwap: boolean
}

export interface IToken {
  symbol: string
  assetKey: string
  address: string
  decimals: number
  icon?: string
  balance?: string
}
interface ITx {
  summary: string
  hash: string
  time: string
  status: 0 | 1
}

let baseTokens: IToken[], defaultTokens: IToken[]
export const initStoreTokens = async () => {
  const localTokens: ITokens = storage.get('localTokens')
  if (localTokens?.defaultTokens?.length) {
    baseTokens = localTokens.baseTokens
    defaultTokens = localTokens.defaultTokens
    fetchTokens()
  } else {
    await fetchTokens()
  }
}
const fetchTokens = async () => {
  const tokens = await getTokens()
  baseTokens = tokens.baseTokens
  defaultTokens = tokens.defaultTokens
  storage.set('localTokens', tokens)
}

function equalToken(tokenA: IToken, tokenB: IToken) {
  return (
    tokenA.assetKey + tokenA.address.toLowerCase() ===
    tokenB.assetKey + tokenB.address.toLowerCase()
  )
}

function initTokens() {
  const configTokens = defaultTokens
  let localTokens: IToken[] = storage.get('tokens') || []
  // delete local token balance
  const withBalance = localTokens.some(v => v.balance)
  if (withBalance) {
    localTokens = localTokens.map(v => {
      delete v.balance
      return v
    })
    storage.set('tokens', localTokens)
  }
  const filterdLocalTokens = localTokens.filter(
    v => !configTokens.some(k => equalToken(v, k))
  )
  return [...configTokens, ...filterdLocalTokens]
}

function addWAssetAddress(asset: IToken) {
  if (!asset.address && asset.assetKey !== NULSKey) {
    return calcWAssetAddress(asset.assetKey)
  }
  return asset.address
}

const MAX_TXS = 20

export const useUserStore = defineStore('user', {
  state: (): UserState => ({
    blockHeight: '',
    tokens: initTokens(),
    tokenWithBalances: initTokens(),
    baseTokens,
    allTxs: storage.get('txs') || {},
    slippageTolerance: storage.get('slippageTolerance') || '0.5',
    refreshSwap: false
  }),

  getters: {
    myTxs(state) {
      const { address } = useWalletStore()
      return state.allTxs[address.toLowerCase()] || []
    },
    focusTokens(state) {
      return state.tokens.filter(
        v => !defaultTokens.some(k => equalToken(k, v))
      )
    },
    defaultTokens(state) {
      const NULS = state.tokens.find(v => v.assetKey === NULSKey)!
      const NSWAP = state.tokens.find(v => v.assetKey === NSWAPKey)!
      const USDTN = state.tokens.find(v => v.symbol === 'USDTN')!
      const NABOX = state.tokens.find(v => v.symbol === 'NABOX')!
      const otherToken = USDTN ||  NSWAP || NABOX || state.tokens[0]
      return [NULS, otherToken]
    },
    baseTokensKeys(state) {
      return state.baseTokens.map(v => v.assetKey)
    }
  },

  actions: {
    async updateBlockHeight() {
      const result = await getLatestHeight()
      this.blockHeight = result.result || ''
    },
    addToken(token: IToken) {
      const index = this.tokens.findIndex(v => equalToken(v, token))
      if (index > -1) {
        this.tokens.splice(index, 1, token)
      } else {
        this.tokens.push(token)
      }
      this.fetTokenBalances()
      storage.set('tokens', this.tokens)
    },

    removeToken(token: IToken) {
      const index = this.tokens.findIndex(v => equalToken(v, token))
      this.tokens.splice(index, 1)
      this.fetTokenBalances()
      storage.set('tokens', this.tokens)
    },

    addTx(tx: ITx) {
      const { address } = useWalletStore()
      if (!this.allTxs[address.toLowerCase()]) {
        this.allTxs[address.toLowerCase()] = []
      }
      this.allTxs[address.toLowerCase()].unshift(tx)
      if (this.allTxs[address.toLowerCase()].length > MAX_TXS) {
        this.allTxs[address.toLowerCase()].pop()
      }
      storage.set('txs', this.allTxs)
    },

    // fetch token balance and sync tokens and tokenWithBalances
    async fetTokenBalances() {
      const { address } = useWalletStore()
      if (!address) {
        this.tokenWithBalances = []
        return
      }
      const assetsInfo = this.tokens.map(v => ({
        chainId: +v.assetKey.split('-')[0],
        assetId: +v.assetKey.split('-')[1],
        contractAddress: v.address
      }))
      const result = await getNAssetsBalance(address, assetsInfo)
      this.tokenWithBalances = this.tokens.map((v, i) => {
        const token = result[i]
        return {
          ...v,
          balance: divisionDecimals(token.balance, v.decimals)
        }
      })
    },

    async updateTxs() {
      const { address } = useWalletStore()
      if (!this.allTxs[address.toLowerCase()]) {
        this.allTxs[address.toLowerCase()] = []
      }

      const unConfirmedTxs = this.allTxs[address.toLowerCase()].filter(
        v => v.status !== 1
      )
      if (unConfirmedTxs.length) {
        const result = await getTxs(unConfirmedTxs.map(v => v.hash))
        this.allTxs[address.toLowerCase()].map(tx => {
          result.map(v => {
            if (tx.hash === v.hash) {
              tx.status = v.status || 0
            }
          })
        })
        storage.set('txs', this.allTxs)
      }
    },

    changeSlippageTolerance(val: string) {
      this.slippageTolerance = val
      storage.set('slippageTolerance', val)
    },

    changeRefresh() {
      this.refreshSwap = true
      setTimeout(() => {
        this.refreshSwap = false
      }, 1500)
    }
  }
})
