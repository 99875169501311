<template>
  <div class="card p-4">
    <div class="flex justify-between mb-3">
      <div class="text-gray-400 text-sm mr-2">{{ token.symbol }}</div>
      <div class="text-gray-400 text-sm break-all text-right" v-if="address">
        Balance: {{ token.balance || '--' }}
      </div>
    </div>
    <div class="flex items-center justify-between gap-3">
      <div class="flex-1">
        <input
          type="text"
          class="w-full bg-transparent text-2xl text-white focus:outline-none placeholder-gray-600"
          :placeholder="placeholder || '0.0'"
          :value="value"
          @input="handleInput" />
      </div>

      <button
        class="token-select-btn flex items-center gap-2 px-4 py-2 rounded-xl bg-background-dark/80 hover:bg-background-dark transition-all duration-200"
        @click="$emit('select-token')">
        <img
          :src="token.icon || defaultIcon"
          class="h-6 w-6 rounded-full"
          :alt="token.symbol" />
        <span class="text-white font-medium">{{ token.symbol }}</span>
        <chevron-down-icon class="h-4 w-4 text-gray-400" />
      </button>
    </div>

    <div v-if="showPercentages" class="flex gap-2 mt-4">
      <button
        v-for="percent in [25, 50, 75, 100]"
        :key="percent"
        :class="
          clsxm(
            'px-3 py-1 rounded-lg text-sm font-medium bg-primary/10 hover:bg-primary/30 text-primary transition-all duration-200',
            isOnPercent(percent) && 'bg-primary/30'
          )
        "
        @click="$emit('select-percentage', percent)">
        {{ percent === 100 ? 'MAX' : `${percent}%` }}
      </button>
    </div>

    <!-- <div v-if="usdValue" class="text-sm text-gray-400 mt-2">
      ~{{ usdValue }} USD
    </div> -->
  </div>
</template>

<script setup lang="ts">
import { ChevronDownIcon } from '@heroicons/vue/24/outline'
import defaultIcon from '@/assets/images/token.svg'
import { IToken } from '@/stores/user'
import clsxm from '@/utils/clsxm'
import { Times, Division, fixNumber, createRegex } from '@/utils/utils'

const props = defineProps<{
  address: string
  token: IToken
  placeholder?: string
  value: string
  showPercentages?: boolean
}>()

// const emit = defineEmits([
//   'update:modelValue',
//   'change',
//   'select-token',
//   'select-percentage'
// ])

const emit = defineEmits<{
  (e: 'update:modelValue'): void
  (e: 'change', val: string): void
  (e: 'select-token'): void
  (e: 'select-percentage', percent: number): void
}>()

let localValue = props.value
const handleInput = (event: Event) => {
  const inputElement = event.target as HTMLInputElement
  const reg = createRegex(props.token.decimals)
  if (!inputElement.value || reg.test(inputElement.value)) {
    emit('change', inputElement.value)
    localValue = inputElement.value
  } else {
    inputElement.value = localValue
  }
}

const isOnPercent = (percent: number) => {
  return (
    props.value ===
    fixNumber(
      Division(Times(props.token.balance || '0', percent), 100).toFixed(),
      props.token.decimals
    )
  )
}

// defineProps({
//   label: String,
//   balance: String,
//   placeholder: String,
//   modelValue: [String, Number],
//   tokenIcon: String,
//   tokenSymbol: String,
//   showPercentages: Boolean,
//   usdValue: String
// })
</script>

<style scoped>
.token-select-btn {
  border: 1px solid rgba(255, 255, 255, 0.05);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.token-select-btn:hover {
  border-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}
</style>
