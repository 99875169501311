import { getDeadLine } from '.'
import { ROUTER_ADDRESS, BURN_ADDRESS } from '@/constant/contract'

/*---------------1.swap nuls for token-------------*/
// swap exact nuls for token
export async function swapExactNulsForTokens(
  owner: string,
  value: string,
  amountOutMin: string,
  path: string[]
) {
  const data = {
    from: owner,
    value: value,
    contractAddress: ROUTER_ADDRESS,
    methodName: 'swapExactNulsForTokens',
    methodDesc: '',
    args: [
      amountOutMin,
      path,
      owner,
      getDeadLine(),
      BURN_ADDRESS
    ]
  }
  return await window.nabox.contractCall(data)
}

// reverse: swap nuls for exact token
export async function swapNulsForExactTokens(
  owner: string,
  NULSValueInMax: string,
  amountOut: string,
  path: string[]
) {
  const data = {
    from: owner,
    value: NULSValueInMax,
    contractAddress: ROUTER_ADDRESS,
    methodName: 'swapNulsForExactTokens',
    methodDesc: '',
    args: [
      amountOut,
      path,
      owner,
      getDeadLine(),
      BURN_ADDRESS
    ]
  }
  return await window.nabox.contractCall(data)
}

/*---------------2.swap nuls for wasset-------------*/
// swap exact nuls for wasset
export async function swapExactNulsForWAsset(
  owner: string,
  NULSValue: string, // 0.1
  amountOutMin: string,
  wAssetKey: string,
  path: string[]
) {
  const [chainId, assetId] = wAssetKey.split('-')

  const data = {
    from: owner,
    value: NULSValue,
    contractAddress: ROUTER_ADDRESS,
    methodName: 'swapExactNulsForWAsset',
    methodDesc: '',
    args: [
      chainId,
      assetId,
      amountOutMin,
      path,
      owner,
      getDeadLine(),
      BURN_ADDRESS
    ]
  }
  return await window.nabox.contractCall(data)
}

// reverse: swap nuls for exact wasset
export async function swapNulsForExactWAsset(
  owner: string,
  wAssetKey: string,
  NULSValueInMax: string, // 0.1
  amountOut: string,
  path: string[]
) {
  const [chainId, assetId] = wAssetKey.split('-')
  const data = {
    from: owner,
    value: NULSValueInMax,
    contractAddress: ROUTER_ADDRESS,
    methodName: 'swapNulsForExactWAsset',
    methodDesc: '',
    args: [
      chainId,
      assetId,
      amountOut,
      path,
      owner,
      getDeadLine(),
      BURN_ADDRESS
    ]
  }
  return await window.nabox.contractCall(data)
}


/*---------------3.swap token for nuls-------------*/
// swap exact token for nuls
export async function swapExactTokensForNuls(
  owner: string,
  amountIn: string,
  amountOutMin: string,
  path: string[]
) {
  const data = {
    from: owner,
    value: 0,
    contractAddress: ROUTER_ADDRESS,
    methodName: 'swapExactTokensForNuls',
    methodDesc: '',
    args: [
      amountIn,
      amountOutMin,
      path,
      owner,
      getDeadLine(),
      BURN_ADDRESS
    ]
  }
  return await window.nabox.contractCall(data)
}

// reverse: swap token for exact nuls
export async function swapTokensForExactNuls(
  owner: string,
  amountOut: string,
  amountInMax: string,
  path: string[]
) {
  const data = {
    from: owner,
    value: 0,
    contractAddress: ROUTER_ADDRESS,
    methodName: 'swapTokensForExactNuls',
    methodDesc: '',
    args: [
      amountOut,
      amountInMax,
      path,
      owner,
      getDeadLine(),
      BURN_ADDRESS
    ]
  }
  return await window.nabox.contractCall(data)
}

/*---------------4.swap token for token-------------*/
// swap exact token for token
export async function swapExactTokensForTokens(
  owner: string,
  amountIn: string,
  amountOutMin: string,
  path: string[]
) {
  const data = {
    from: owner,
    value: 0,
    contractAddress: ROUTER_ADDRESS,
    methodName: 'swapExactTokensForTokens',
    methodDesc: '',
    args: [
      amountIn,
      amountOutMin,
      path,
      owner,
      getDeadLine(),
      BURN_ADDRESS
    ]
  }
  return await window.nabox.contractCall(data)
}

// reverse: swap token for exact token
export async function swapTokensForExactTokens(
  owner: string,
  amountInMax: string,
  amountOut: string,
  path: string[]
) {
  const data = {
    from: owner,
    value: 0,
    contractAddress: ROUTER_ADDRESS,
    methodName: 'swapTokensForExactTokens',
    methodDesc: '',
    args: [
      amountOut,
      amountInMax,
      path,
      owner,
      getDeadLine(),
      BURN_ADDRESS
    ]
  }
  return await window.nabox.contractCall(data)
}


/*---------------5.swap token for wasset-------------*/
// swap exact token for wasset
export async function swapExactTokensForWAsset(
  owner: string,
  wAssetKey: string,
  amountIn: string, // 100000000
  amountOutMin: string,
  path: string[]
) {
  const [chainId, assetId] = wAssetKey.split('-')
  const data = {
    from: owner,
    value: 0,
    contractAddress: ROUTER_ADDRESS,
    methodName: 'swapExactTokensForWAsset',
    methodDesc: '',
    args: [
      chainId,
      assetId,
      amountIn,
      amountOutMin,
      path,
      owner,
      getDeadLine(),
      BURN_ADDRESS
    ]
  }
  return await window.nabox.contractCall(data)
}

// reverse: swap token for exact wasset
export async function swapTokensForExactWAsset(
  owner: string,
  wAssetKey: string,
  amountInMax: string,
  amountOut: string,
  path: string[]
) {
  const [chainId, assetId] = wAssetKey.split('-')
  const data = {
    from: owner,
    value: 0,
    contractAddress: ROUTER_ADDRESS,
    methodName: 'swapTokensForExactWAsset',
    methodDesc: '',
    args: [
      chainId,
      assetId,
      amountOut,
      amountInMax,
      path,
      owner,
      getDeadLine(),
      BURN_ADDRESS
    ]
  }
  return await window.nabox.contractCall(data)
}

/*---------------6.wasset for nuls-------------*/
// swap exact wasset for nuls
export async function swapExactWAssetForNuls(
  owner: string,
  wAssetKey: string,
  wAssetAmount: string, // 0.1
  amountOutMin: string,
  path: string[]
) {
  const [chainId, assetId] = wAssetKey.split('-')
  const multyAssetValues = [[wAssetAmount, +chainId, +assetId]]

  const data = {
    from: owner,
    value: 0,
    contractAddress: ROUTER_ADDRESS,
    methodName: 'swapExactWAssetForNuls',
    methodDesc: '',
    args: [
      chainId,
      assetId,
      amountOutMin,
      path,
      owner,
      getDeadLine(),
      BURN_ADDRESS
    ],
    multyAssetValues
  }
  return await window.nabox.contractCall(data)
}

// reverse: swap wasset for exact nuls
export async function swapWAssetForExactNuls(
  owner: string,
  wAssetKey: string,
  wAssetAmountInMax: string, // 0.1
  amountOut: string,
  path: string[]
) {
  const [chainId, assetId] = wAssetKey.split('-')
  const multyAssetValues = [[wAssetAmountInMax, +chainId, +assetId]]
  const data = {
    from: owner,
    value: 0,
    contractAddress: ROUTER_ADDRESS,
    methodName: 'swapWAssetForExactNuls',
    methodDesc: '',
    args: [
      chainId,
      assetId,
      amountOut,
      path,
      owner,
      getDeadLine(),
      BURN_ADDRESS
    ],
    multyAssetValues
  }
  return await window.nabox.contractCall(data)
}

/*---------------7.wasset for wasset-------------*/
// swap exact wasset for wasset
export async function swapExactWAssetForWAsset(
  owner: string,
  wAssetAKey: string,
  wAssetBKey: string,
  wAssetAAmount: string, // 0.1
  amountOutMin: string,
  path: string[]
) {
  const [chainId1, assetId1] = wAssetAKey.split('-')
  const [chainId2, assetId2] = wAssetBKey.split('-')
  const multyAssetValues = [[wAssetAAmount, +chainId1, +assetId1]]

  const data = {
    from: owner,
    value: 0,
    contractAddress: ROUTER_ADDRESS,
    methodName: 'swapExactWAssetForWAsset',
    methodDesc: '',
    args: [
      chainId1,
      assetId1,
      chainId2,
      assetId2,
      amountOutMin,
      path,
      owner,
      getDeadLine(),
      BURN_ADDRESS
    ],
    multyAssetValues
  }
  return await window.nabox.contractCall(data)
}

// reverse: swap wasset for exact wasset
export async function swapWAssetForExactWAsset(
  owner: string,
  wAssetAKey: string,
  wAssetBKey: string,
  wAssetAmountInMax: string, // 0.1
  amountOut: string,
  path: string[]
) {
  const [chainId1, assetId1] = wAssetAKey.split('-')
  const [chainId2, assetId2] = wAssetBKey.split('-')
  const multyAssetValues = [[wAssetAmountInMax, +chainId1, +assetId1]]

  const data = {
    from: owner,
    value: 0,
    contractAddress: ROUTER_ADDRESS,
    methodName: 'swapWAssetForExactWAsset',
    methodDesc: '',
    args: [
      chainId1,
      assetId1,
      chainId2,
      assetId2,
      amountOut,
      path,
      owner,
      getDeadLine(),
      BURN_ADDRESS
    ],
    multyAssetValues
  }
  return await window.nabox.contractCall(data)
}

/*---------------8.swap wasset for token-------------*/
// swap exact wasset for token
export async function swapExactWAssetForTokens(
  owner: string,
  wAssetKey: string,
  wAssetAmount: string, // 0.1
  amountOutMin: string,
  path: string[]
) {
  const [chainId, assetId] = wAssetKey.split('-')
  const multyAssetValues = [[wAssetAmount, +chainId, +assetId]]

  const data = {
    from: owner,
    value: 0,
    contractAddress: ROUTER_ADDRESS,
    methodName: 'swapExactWAssetForTokens',
    methodDesc: '',
    args: [
      chainId,
      assetId,
      amountOutMin,
      path,
      owner,
      getDeadLine(),
      BURN_ADDRESS
    ],
    multyAssetValues
  }
  console.log(data, '==swapExactWAssetForTokens==')
  return await window.nabox.contractCall(data)
}

// reverse: swap wasset for exact token
export async function swapWAssetForExactTokens(
  owner: string,
  wAssetKey: string,
  wAssetAmountInMax: string, // 0.1
  amountOut: string,
  path: string[]
) {
  const [chainId, assetId] = wAssetKey.split('-')
  const multyAssetValues = [[wAssetAmountInMax, +chainId, +assetId]]
  const data = {
    from: owner,
    value: 0,
    contractAddress: ROUTER_ADDRESS,
    methodName: 'swapWAssetForExactTokens',
    methodDesc: '',
    args: [
      chainId,
      assetId,
      amountOut,
      path,
      owner,
      getDeadLine(),
      BURN_ADDRESS
    ],
    multyAssetValues
  }
  return await window.nabox.contractCall(data)
}
