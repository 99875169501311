<template>
  <BaseModal :show="show" @close="closeModal" max-width="md">
    <div class="modal-wrap">
      <div class="flex items-center mb-6">
        <button
          class="p-2 -ml-2 rounded-lg text-gray-400 hover:text-white hover:bg-gray-700/50 transition-all duration-200"
          @click="closeModal">
          <arrow-left-icon class="h-5 w-5" />
        </button>
        <div class="ml-2">
          <h3 class="text-xl font-bold text-white">
            Remove {{ lp.lpToken.symbol }} LP
          </h3>
          <!-- <p class="text-sm text-gray-400">
            Receive LP tokens and earn 0.3% trading fees
          </p> -->
        </div>
      </div>

      <div class="space-y-4">
        <div class="card p-4">
          <div class="flex justify-between mb-3">
            <span class="text-gray-400 text-sm">{{ lp.lpToken.symbol }}</span>
            <span class="text-gray-400 text-sm">Liquidity: {{ lp.value }}</span>
          </div>
          <div class="flex items-center justify-between gap-3">
            <div class="flex-1">
              <input
                type="text"
                class="w-full bg-transparent text-2xl text-white focus:outline-none placeholder-gray-600"
                placeholder="0.0"
                v-model="liq" />
            </div>
          </div>

          <div class="flex gap-2 mt-4">
            <button
              v-for="percent in [25, 50, 75, 100]"
              :key="percent"
              :class="
                clsxm(
                  'px-3 py-1 rounded-lg text-sm font-medium bg-primary/10 hover:bg-primary/30 text-primary transition-all duration-200',
                  isOnPercent(percent) && 'bg-primary/30'
                )
              "
              @click="selectPercentage(percent)">
              {{ percent === 100 ? 'MAX' : `${percent}%` }}
            </button>
          </div>
        </div>
      </div>

      <div class="mt-6 p-4 rounded-xl bg-gray-900/50">
        <h4 class="text-white font-medium mb-4">You will receive</h4>
        <div class="space-y-2">
          <div class="flex justify-between">
            <span class="text-gray-400">{{ lp.tokenA.symbol }}</span>
            <span class="text-white">{{ receiveAmount.tokenA || '-' }}</span>
          </div>
          <div class="flex justify-between">
            <span class="text-gray-400">{{ lp.tokenB.symbol }}</span>
            <span class="text-white">{{ receiveAmount.tokenB || '-' }}</span>
          </div>
        </div>
      </div>

      <button
        class="w-full px-4 py-3 rounded-xl bg-primary text-white hover:bg-primary-dark transition-all duration-200 mt-6"
        :class="{ 'opacity-50 cursor-not-allowed': !canSupply }"
        @click="handleConfirm">
        {{ removeButtonText }}
      </button>
    </div>
  </BaseModal>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import clsxm from '@/utils/clsxm'
import { ArrowLeftIcon } from '@heroicons/vue/24/outline'
import BaseModal from '@/components/ui/BaseModal.vue'
import { Times, Division, fixNumber, timesDecimals } from '@/utils/utils'
import { useTokenAllowance } from '../hooks/useAllowance'
import useRemoveLiquidity from '../hooks/useRemoveLiquidity'
import { ROUTER_ADDRESS } from '@/constant/contract'
import { ILP } from '../hooks/useUserLPs'
import { LoadingService } from '@/components/Loading/loading-directives'

const props = defineProps<{
  show: boolean
  lp: ILP
}>()

const emit = defineEmits<{
  (e: 'close'): void
}>()

const { liq, receiveAmount, removeLiquidity } = useRemoveLiquidity(props.lp)
const { allowanceLoading, tokenNeedApprove, approveAllowance } =
  useTokenAllowance(ref(props.lp.lpToken), liq, ROUTER_ADDRESS)

const isOnPercent = (percent: number) => {
  return (
    liq.value ===
    fixNumber(Division(Times(props.lp.value || '0', percent), 100).toFixed(), 8)
  )
}

const canSupply = computed(() => {
  return liq.value && !allowanceLoading.value
})

const removeButtonText = computed(() => {
  if (!liq.value) return 'Enter an amount'
  if (tokenNeedApprove.value) return `Approve ${props.lp.lpToken.symbol}`
  return 'Remove'
})

const selectPercentage = (percent: number) => {
  liq.value = fixNumber(
    Division(Times(props.lp.value || '0', percent), 100).toFixed(),
    8
  )
}

const handleConfirm = async () => {
  LoadingService.show()
  if (tokenNeedApprove.value) {
    const { address, decimals } = props.lp.lpToken
    await approveAllowance(address, timesDecimals(liq.value, decimals))
  } else {
    await removeLiquidity()
    closeModal()
  }
  LoadingService.hide()
}

const closeModal = () => {
  emit('close')
  liq.value = ''
}
</script>
