type IMODE = 'beta' | 'prod'
const MODE = import.meta.env.MODE as IMODE

const config = {
  beta: {
    baseUrl: 'https://beta.api.nuls.io/jsonrpc',
    chainId: 2,
    scanUrl: 'https://beta.nulscan.io'
  },
  prod: {
    baseUrl: 'https://api.nuls.io/jsonrpc',
    chainId: 1,
    scanUrl: 'https://nulscan.io'
  }
}

export default config[MODE]
