<template>
  <BaseModal :show="show" @close="$emit('close')">
    <div class="modal-wrap">
      <div class="flex justify-between items-center mb-6">
        <h3 class="text-xl font-bold text-white">Select a token</h3>
        <button class="text-gray-400 hover:text-white" @click="$emit('close')">
          <x-mark-icon class="h-5 w-5" />
        </button>
      </div>

      <div class="relative mb-4">
        <input
          v-model="searchQuery"
          type="text"
          placeholder="Search name or paste address"
          class="w-full px-4 py-3 rounded-xl bg-gray-700/50 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primary" />
        <magnifying-glass-icon
          class="absolute right-4 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
      </div>

      <div class="flex items-center justify-between mb-4" v-if="!onlyBaseTokens">
        <h4 class="text-sm font-medium text-gray-400">Common tokens</h4>
        <button
          class="text-primary text-sm hover:underline"
          @click="showManageTokens = true">
          Manage Tokens
        </button>
      </div>

      <div class="space-y-1 max-h-[500px] overflow-y-auto">
        <button
          v-for="token in filteredTokens"
          :key="token.symbol"
          class="w-full flex items-center justify-between py-2 px-1 rounded-xl hover:bg-gray-700/50 transition-colors"
          @click="selectToken(token)">
          <div class="flex items-center shrink-0">
            <img
              :src="token.icon"
              class="w-8 h-8 rounded-full"
              :alt="token.symbol" />
            <div class="ml-1 text-left md:ml-3">
              <div class="text-white font-medium">{{ token.symbol }}</div>
              <div class="text-sm text-gray-400">
                {{
                  token.address
                    ? superLong(token.address, 5)
                    : NULSKey !== token.assetKey
                      ? token.assetKey
                      : ''
                }}
              </div>
            </div>
          </div>
          <div class="text-right flex-1" v-if="walletStore.address">
            <div class="text-white break-all">{{ token.balance }}</div>
          </div>
        </button>
      </div>
    </div>

    <ManageTokensModal
      :show="showManageTokens"
      @close="showManageTokens = false" />
  </BaseModal>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { XMarkIcon, MagnifyingGlassIcon } from '@heroicons/vue/24/outline'
import BaseModal from '@/components/ui/BaseModal.vue'
import ManageTokensModal from './ManageTokensModal.vue'
import { useUserStore } from '@/stores/user'
import { useWalletStore } from '@/stores/wallet'
import { superLong } from '@/utils/utils'
import { NULSKey } from '@/constant/contract'

const props = defineProps<{
  show: boolean
  onlyBaseTokens?: boolean
}>()

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'select', token: any): void
}>()

const store = useUserStore()
const walletStore = useWalletStore()

const searchQuery = ref('')
const showManageTokens = ref(false)

const filteredTokens = computed(() => {
  const sortedTokens = [...store.tokenWithBalances].sort((a, b) => {
    // @ts-ignore
    return a.balance - b.balance > 0 ? -1 : 1
  })
  const tokens = sortedTokens.filter(token => {
    if (props.onlyBaseTokens) return store.baseTokensKeys.includes(token.assetKey)
    return true
  })
  if (!searchQuery.value) return tokens
  const query = searchQuery.value.toLowerCase()
  return sortedTokens.filter(
    token =>
      token.symbol.toLowerCase().includes(query) ||
      token.address.toLowerCase() === query.toLocaleLowerCase() ||
      token.assetKey.toLowerCase().includes(query)
  )
})

const selectToken = (token: any) => {
  emit('select', token)
  emit('close')
}
</script>
