<template>
  <div ref="reference" @mouseenter="show" @mouseleave="hide">
    <slot
      ><QuestionMarkCircleIcon
        class="h-4 w-4 ml-1 group-hover:opacity-70 transition-opacity"
    /></slot>
  </div>

  <Teleport to="body">
    <transition
      enter-active-class="transition-opacity duration-200 ease-out"
      enter-from-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="transition-opacity duration-150 ease-in"
      leave-from-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95">
      <div
        v-if="isVisible"
        ref="floating"
        :style="floatingStyles"
        :class="clsxm('tooltip', props.class)"
        @mouseenter="show"
        @mouseleave="hide">
        {{ text }}
        <div class="arrow" :data-placement="placement" />
      </div>
    </transition>
  </Teleport>
</template>

<script setup lang="ts">
import { ref, onBeforeUnmount } from 'vue'
import { useFloating, autoUpdate, offset, flip, shift } from '@floating-ui/vue'
import { QuestionMarkCircleIcon } from '@heroicons/vue/24/outline'
import { useDelayedToggle } from './useDelayedToggle'
import clsxm from '@/utils/clsxm'

const props = withDefaults(
  defineProps<{
    text: string
    placement?: 'top' | 'bottom' | 'left' | 'right'
    class?: string
    hideDelay?: number
  }>(),
  {
    placement: 'top',
    class: 'w-[300px]',
    hideDelay: 100
  }
)

const reference = ref<HTMLElement | null>(null)
const floating = ref<HTMLElement | null>(null)

const { isVisible, show, hide, cleanup } = useDelayedToggle(props.hideDelay)

const { floatingStyles } = useFloating(reference, floating, {
  placement: props.placement,
  whileElementsMounted: autoUpdate,
  middleware: [offset(8), flip(), shift()]
})
onBeforeUnmount(cleanup)
</script>

<style scoped>
.tooltip {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 16px;
  border-radius: 16px;
  font-size: 14px;
  line-height: 1.3;
  z-index: 9999;
  transform-origin: center;
}

.arrow {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
  transform: rotate(45deg);
}

.arrow[data-placement='top'] {
  bottom: -4px;
  left: calc(50% - 4px);
}

.arrow[data-placement='bottom'] {
  top: -4px;
  left: calc(50% - 4px);
}

.arrow[data-placement='left'] {
  right: -4px;
  top: calc(50% - 4px);
}

.arrow[data-placement='right'] {
  left: -4px;
  top: calc(50% - 4px);
}
</style>


