import { ref, watch } from 'vue'

export function useDelayedToggle(delay = 200) {
  const isVisible = ref(false)
  let timeoutId: number | null = null

  const show = () => {
    if (timeoutId) {
      clearTimeout(timeoutId)
      timeoutId = null
    }
    isVisible.value = true
  }

  const hide = () => {
    timeoutId = window.setTimeout(() => {
      isVisible.value = false
    }, delay)
  }

  // Clean up timeout on component unmount
  const cleanup = () => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
  }

  return {
    isVisible,
    show,
    hide,
    cleanup
  }
}