import { ref, Ref, watch, computed } from 'vue'
import { useWalletStore } from '@/stores/wallet'
import { getTokenAllowance, approveToken } from '@/utils/nuls'
import { Minus, timesDecimals } from '@/utils/utils'
import useToast from '@/hooks/useToast'
import { IToken } from '@/stores/user'

export default function useAllowance(
  tokenA: Ref<IToken>,
  tokenB: Ref<IToken>,
  amountA: Ref<string>,
  amountB: Ref<string>,
  contractAddress: string
) {
  const store = useWalletStore()
  const { toastSuccess, toastError } = useToast()

  const tokenANeedApprove = ref(false)
  const tokenBNeedApprove = ref(false)
  const tokenALoading = ref(true)
  const tokenBLoading = ref(true)

  const allowanceLoading = computed(() => {
    return tokenALoading.value || tokenBLoading.value
  })

  let checkTimer: number
  let loadingATimer: number
  let loadingBTimer: number
  const changeLoading = (loading: boolean, isTokenA: boolean) => {
    if (isTokenA) {
      tokenALoading.value = loading
      if (!loading) {
        clearInterval(loadingATimer)
      }
    } else {
      tokenBLoading.value = loading
      if (!loading) {
        clearInterval(loadingBTimer)
      }
    }
  }

  watch(
    () => [tokenA.value.address, amountA.value],
    ([tokenAddress, amount]) => {
      if (!tokenAddress || !amount) {
        changeLoading(false, true)
        tokenANeedApprove.value = false
      } else {
        clearTimeout(checkTimer)
        checkTimer = window.setTimeout(() => {
          const _amount = timesDecimals(amount, tokenA.value.decimals)
          checkAllowance(tokenAddress, _amount).then(res => {
            tokenANeedApprove.value = res
          })
        }, 500)
      }
    },
    { immediate: true }
  )

  watch(
    () => [tokenB.value.address, amountB.value],
    ([tokenAddress, amount]) => {
      if (!tokenAddress || !amount) {
        changeLoading(false, false)
        tokenBNeedApprove.value = false
      } else {
        clearTimeout(checkTimer)
        checkTimer = window.setTimeout(() => {
          const _amount = timesDecimals(amount, tokenB.value.decimals)
          checkAllowance(tokenAddress, _amount).then(res => {
            tokenBNeedApprove.value = res
          })
        }, 500)
      }
    },
    { immediate: true }
  )

  const checkAllowance = async (tokenContract: string, amount: string) => {
    if (!contractAddress) return false
    const isTokenA = tokenContract === tokenA.value.address
    changeLoading(true, isTokenA)
    const allowance = await getTokenAllowance(
      tokenContract,
      store.address,
      contractAddress
    )
    changeLoading(false, isTokenA)
    return Minus(allowance, amount).toNumber() < 0
    
  }

  const approveAllowance = async (tokenContract: string, amount: string) => {
    let hash
    try {
      hash = await approveToken(
        tokenContract,
        store.address,
        amount,
        contractAddress
      )
      toastSuccess('Success')
    } catch (e) {
      toastError(e)
    }
    if (hash) {
      const isTokenA = tokenContract === tokenA.value.address
      if (isTokenA) {
        loadingATimer = window.setInterval(() => {
          const _amount = timesDecimals(amountA.value, tokenA.value.decimals)
          checkAllowance(tokenA.value.address, _amount).then(
            res => (tokenANeedApprove.value = res)
          )
        }, 10000)
      } else {
        loadingBTimer = window.setInterval(() => {
          const _amount = timesDecimals(amountB.value, tokenB.value.decimals)
          checkAllowance(tokenB.value.address, _amount).then(
            res => (tokenBNeedApprove.value = res)
          )
        }, 10000)
      }
    }
  }

  return {
    allowanceLoading,
    checkAllowance,
    approveAllowance,
    tokenANeedApprove,
    tokenBNeedApprove
  }
}

export function useTokenAllowance(
  token: Ref<IToken>,
  amount: Ref<string>,
  contractAddress: string
) {
  const store = useWalletStore()
  const { toastSuccess, toastError } = useToast()

  const tokenNeedApprove = ref(false)
  const tokenLoading = ref(true)

  const allowanceLoading = computed(() => {
    return tokenLoading.value
  })

  let checkTimer: number
  let loadingTimer: number = 0
  const changeLoading = (loading: boolean) => {
    tokenLoading.value = loading
    if (!loading) {
      clearInterval(loadingTimer)
    }
  }

  watch(
    () => [token.value.address, amount.value],
    ([tokenAddress, amount]) => {
      if (!tokenAddress || !amount) {
        changeLoading(false)
        tokenNeedApprove.value = false
      } else {
        clearTimeout(checkTimer)
        checkTimer = window.setTimeout(() => {
          const _amount = timesDecimals(amount, token.value.decimals)
          checkAllowance(tokenAddress, _amount).then(res => {
            tokenNeedApprove.value = res
          })
        }, 500)
      }
    },
    { immediate: true }
  )


  const checkAllowance = async (tokenContract: string, amount: string) => {
    if (!contractAddress) return false
    changeLoading(true)
    const allowance = await getTokenAllowance(
      tokenContract,
      store.address,
      contractAddress
    )
    changeLoading(false)
    return Minus(allowance, amount).toNumber() < 0
  }

  const approveAllowance = async (tokenContract: string, _amount: string) => {
    let hash
    try {
      hash = await approveToken(
        tokenContract,
        store.address,
        _amount,
        contractAddress
      )
      toastSuccess('Success')
    } catch (e) {
      toastError(e)
    }
    if (hash) {
      loadingTimer = window.setInterval(() => {
        const _amount = timesDecimals(amount.value, token.value.decimals)
        checkAllowance(token.value.address, _amount).then(
          res => (tokenNeedApprove.value = res)
        )
      }, 10000)
    }
  }

  return {
    allowanceLoading,
    checkAllowance,
    approveAllowance,
    tokenNeedApprove
  }
}
