import { computed, ref, watch } from 'vue'
import _ from 'lodash'
import { useWalletStore } from '@/stores/wallet'
import { useUserStore, IToken } from '@/stores/user'
import {
  getUserLPs,
  getPairsLPAddresses,
  getLPTotalSupply
} from '@/utils/nuls/multiCall'
import { sortsBefore, calcPairAddress } from '@/utils/nuls'
import { divisionDecimals } from '@/utils/utils'
import { LP_DECIMALS } from '@/constant/exchange'

export interface ILP {
  tokenA: IToken
  tokenB: IToken
  value: string
  lpToken: IToken & { pairAddress: string; totalSupply: string }
}

export default function useUserLPs() {
  const walletStore = useWalletStore()
  const userStore = useUserStore()
  const lps = ref<ILP[]>([])

  const allPairs = computed(() => {
    const userTokens = userStore.tokens
    // const bases = BaseTokens
    const combinedList = _.flatMap(userTokens, token => {
      return userTokens
        .map(base => {
          if (base.assetKey === token.assetKey) {
            return null
          }
          return [base, token]
        })
        .filter(v => v !== null)
    })
    const keyed = combinedList.reduce<{ [key: string]: [IToken, IToken] }>(
      (memo, [tokenA, tokenB]) => {
        const sorted = sortsBefore(tokenA, tokenB)
        const key = sorted
          ? `${tokenA.assetKey}:${tokenB.assetKey}`
          : `${tokenB.assetKey}:${tokenA.assetKey}`
        if (memo[key]) return memo
        memo[key] = sorted ? [tokenA, tokenB] : [tokenB, tokenA]
        return memo
      },
      {}
    )

    return Object.keys(keyed).map(key => keyed[key])
  })

  const getLPs = async (pairs: [IToken, IToken][]) => {
    const pairAddresses = pairs.map(pair => calcPairAddress(pair[0], pair[1]))
    const result = await getUserLPs(pairAddresses, walletStore.address)
    const LPAddresses = await getPairsLPAddresses(pairAddresses)
    const LPTotalSupply = await getLPTotalSupply(LPAddresses)
    const _lps: ILP[] = []
    result.map((v: string, i: number) => {
      _lps.push({
        tokenA: pairs[i][0],
        tokenB: pairs[i][1],
        value: divisionDecimals(v, LP_DECIMALS),
        lpToken: {
          symbol: pairs[i][0].symbol + '-' + pairs[i][1].symbol,
          address: LPAddresses[i],
          decimals: LP_DECIMALS,
          assetKey: '',
          pairAddress: pairAddresses[i],
          totalSupply: LPTotalSupply[i]
        }
      })
    })
    lps.value = _lps.filter(v => v.value && v.value !== '0')
  }

  watch(
    [
      () => allPairs.value,
      () => userStore.blockHeight,
      () => walletStore.address
    ],
    ([pairs, height, address]) => {
      if (!address) {
        lps.value = []
      } else if (pairs?.length && height) {
        getLPs(pairs)
      }
    },
    { immediate: true }
  )

  return {
    lps
  }
}
