import {
  STAKE_CONTRACT,
  STAKE_NULS_CONTRACT,
  STAKE_NULS_EARN_CONTRACT,
  VNSWAP_CONTRACT,
  MULTICALL_ADDRESS
} from '@/constant/contract'
import { invokeView } from '@/service/api'
import { timesDecimals } from '../utils'

// Locked for stake NSWAP
export async function getStakedLocked() {
  const data = {
    contractAddress: MULTICALL_ADDRESS,
    methodName: 'aggregateStrict',
    methodDesc: '',
    args: [
      [STAKE_CONTRACT, VNSWAP_CONTRACT],
      ['totalSupply', 'getTotallyStaked'],
      ['', '', ''],
      false
    ]
  }
  const res = await invokeView(data)
  if (!res.result) return []
  // console.log(res.result, 'getStakedLocked')
  return JSON.parse(res.result)
}


export async function getUserStakeData(userAddress: string) {
  const data = {
    contractAddress: MULTICALL_ADDRESS,
    methodName: 'aggregateStrict',
    methodDesc: '',
    args: [
      [STAKE_CONTRACT, STAKE_CONTRACT, STAKE_CONTRACT],
      ['_balanceOf', 'earned', 'lockedTime'],
      [userAddress, userAddress, userAddress],
      false
    ]
  }
  const res = await invokeView(data)
  if (!res.result) return []
  // console.log(res.result, 'getUserStakeData')
  return JSON.parse(res.result)
}

// stake NSWAP
export async function stakeNswap(owner: string, amount: string) {
  const data = {
    from: owner,
    value: 0,
    contractAddress: STAKE_CONTRACT,
    methodName: 'stake',
    methodDesc: '(BigInteger amount) return void',
    args: [amount]
  }
  return await window.nabox.contractCall(data)
}

// compound NSWAP
export async function compoundNswap(owner: string) {
  const data = {
    from: owner,
    value: 0,
    contractAddress: STAKE_CONTRACT,
    methodName: 'compound',
    methodDesc: '() return void',
    args: []
  }
  return await window.nabox.contractCall(data)
}

// unstake NSWAP
export async function unstakeNswap(owner: string) {
  const data = {
    from: owner,
    value: 0,
    contractAddress: STAKE_CONTRACT,
    methodName: "withdraw",
    methodDesc: "() return BigInteger",
    args: [],
  };
  return await window.nabox.contractCall(data)
}

export async function getAINULSLocked() {
  const data = {
    contractAddress: MULTICALL_ADDRESS,
    methodName: 'aggregateStrict',
    methodDesc: '',
    args: [
      [STAKE_NULS_CONTRACT],
      ['totalSupply'],
      [''],
      false
    ]
    // args: [
    //   [STAKE_NULS_CONTRACT, STAKE_NULS_EARN_CONTRACT, VNSWAP_CONTRACT],
    //   ['totalSupply', 'totalSupply', 'getTotallyStaked'],
    //   ['', '', ''],
    //   false
    // ]
  }
  const res = await invokeView(data)
  if (!res.result) return []
  // console.log(res.result, 'getAINULSLocked')
  return JSON.parse(res.result)
}

/*-------------------stake nuls earn nuls-------------------*/
export async function getStakeNULSEarnNULSData(owner: string) {
  const data = {
    contractAddress: MULTICALL_ADDRESS,
    methodName: 'aggregateStrict',
    methodDesc: '',
    args: [
      [STAKE_NULS_CONTRACT, STAKE_NULS_CONTRACT, STAKE_NULS_CONTRACT],
      ['_balanceOf', 'earnedNuls', 'earned'],
      [owner, owner, owner],
      false
    ]
  }
  const res = await invokeView(data)
  if (!res.result) return []
  // console.log(res.result, 'getStakeNULSEarnNULSData')
  return JSON.parse(res.result)
}

// stake nuls earn nuls
export async function stakeNulsEarnNuls(owner: string, amount: string, decimals: number) {
  const _amount = timesDecimals(amount, decimals)
  const data = {
    from: owner,
    value: amount,
    contractAddress: STAKE_NULS_CONTRACT,
    methodName: 'stake',
    methodDesc: '(BigInteger value) return void',
    args: [_amount]
  }
  return await window.nabox.contractCall(data)
}

// stake nuls earn nuls compound
export async function stakeNulsEarnNulsCompound(owner: string) {
  const data = {
    from: owner,
    value: 0,
    contractAddress: STAKE_NULS_CONTRACT,
    methodName: 'compound',
    methodDesc: '() return BigInteger',
    args: []
  }
  return await window.nabox.contractCall(data)
}

// stake nuls earn nuls unstake all
export async function stakeNulsEarnNulsUnstake(owner: string) {
  const data = {
    from: owner,
    value: 0,
    contractAddress: STAKE_NULS_CONTRACT,
    methodName: 'withdraw',
    methodDesc: '() return void',
    args: []
  }
  return await window.nabox.contractCall(data)
}

/*-------------------stake nuls earn nswap-------------------*/
export async function getStakeNULSEarnNSWAPData(owner: string) {
  const data = {
    contractAddress: MULTICALL_ADDRESS,
    methodName: 'aggregateStrict',
    methodDesc: '',
    args: [
      [STAKE_NULS_EARN_CONTRACT, STAKE_NULS_EARN_CONTRACT],
      ['_balanceOf', 'earned'],
      [owner, owner],
      false
    ]
  }
  const res = await invokeView(data)
  if (!res.result) return []
  // console.log(res.result, 'getStakeNULSEarnNULSData')
  return JSON.parse(res.result)
}

export async function stakeNulsEarnNswap(owner: string, amount: string, decimals: number) {
  const _amount = timesDecimals(amount, decimals)
  const data = {
    from: owner,
    value: amount,
    contractAddress: STAKE_NULS_EARN_CONTRACT,
    methodName: 'stakeWithNuls',
    methodDesc: '(BigInteger amount) return void',
    args: [_amount]
  }
  return await window.nabox.contractCall(data)
}

export async function stakeNulsEarnNswapGetReward(owner: string) {
  const data = {
    from: owner,
    value: 0.05,
    contractAddress: STAKE_NULS_EARN_CONTRACT,
    methodName: "getReward",
    methodDesc: "() return BigInteger",
    args: [],
  };
  return await window.nabox.contractCall(data)
}

export async function stakeNulsEarnNswapUnstake(owner: string, amount: string) {
  const data = {
    from: owner,
    value: 0.05,
    contractAddress: STAKE_NULS_EARN_CONTRACT,
    methodName: "withdraw",
    methodDesc: "(BigInteger b) return BigInteger",
    args: [amount],
  };
  return await window.nabox.contractCall(data)
}


/*-------------------stake nswap earn nuls-------------------*/
export async function getStakeNSWAPEarnNULSData(owner: string) {
  const data = {
    contractAddress: MULTICALL_ADDRESS,
    methodName: 'aggregateStrict',
    methodDesc: '',
    args: [
      [VNSWAP_CONTRACT, VNSWAP_CONTRACT, VNSWAP_CONTRACT, VNSWAP_CONTRACT],
      ['_balanceOf', 'getClaimable', '_userBalDeposited', 'earned'],
      [owner, owner, owner, owner],
      false
    ]
  }
  const res = await invokeView(data)
  if (!res.result) return []
  // console.log(res.result, 'getStakeNULSEarnNULSData')
  return JSON.parse(res.result)
}

export async function stakeNswapEarnNuls(owner: string, amount: string, decimals: number) {
  const _amount = timesDecimals(amount, decimals)
  const data = {
    from: owner,
    value: 0.12,
    contractAddress: VNSWAP_CONTRACT,
    methodName: "stake",
    methodDesc: "(BigInteger amount, Address user) return void",
    args: [_amount, owner],
  };
  return await window.nabox.contractCall(data)
}

export async function stakeNswapEarnNulsHarvest(owner: string) {
  const data = {
    from: owner,
    value: 0.12,
    contractAddress: VNSWAP_CONTRACT,
    methodName: "_claim",
    methodDesc: "(Address _addr) return void",
    args: [owner],
  };
  return await window.nabox.contractCall(data)
}

export async function stakeNswapEarnNulsGetReward(owner: string) {
  const data = {
    from: owner,
    value: 0.12,
    contractAddress: VNSWAP_CONTRACT,
    methodName: "getReward",
    methodDesc: "(Address user) return BigInteger",
    args: [owner],
  };
  return await window.nabox.contractCall(data)
}

export async function stakeNswapEarnNulsUnstake(owner: string) {
  const data = {
    from: owner,
    value: 0.12,
    contractAddress: VNSWAP_CONTRACT,
    methodName: "withdrawAll",
    methodDesc: "() return BigInteger",
    args: [],
  };
  return await window.nabox.contractCall(data)
}