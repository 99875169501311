import { Directive, createApp, App } from 'vue'
import Loading from './Loading.vue'

interface LoadingOptions {
  target?: HTMLElement | string
  text?: string
  lock?: boolean
  fullScreen?: boolean
}

let originalOverflow = ''
let lockCount = 0
const lockScroll = () => {
  lockCount++
  if (lockCount === 1) {
    originalOverflow = document.body.style.overflow
    document.body.style.overflow = 'hidden'
  }
}

const unlockScroll = () => {
  lockCount--
  if (lockCount === 0) {
    document.body.style.overflow = originalOverflow
  }
}

const createLoadingComponent = (options: LoadingOptions) => {
  const { target, text, lock = true, fullScreen = false } = options

  const container = document.createElement('div')
  const loadingApp = createApp(Loading, {
    visible: true,
    text,
    target,
    fullScreen,
    'onAfter-leave': () => {
      loadingApp.unmount()
      container.remove()
      if (lock) {
        unlockScroll()
      }
    }
  })
  const vm = loadingApp.mount(container) as any

  let targetContainer = document.body
  if (target) {
    targetContainer =
      typeof target === 'string'
        ? document.querySelector(target) || targetContainer
        : target
  }
  targetContainer.appendChild(container)

  if (lock) {
    lockScroll()
  }

  return {
    /* update: (nextOptions: Partial<LoadingOptions>) => {
      if ('text' in nextOptions) {
        vm.updateProps(text)
      }
    }, */
    close: () => {
      vm.close()
    }
  }
}

interface LoadingInstance {
  close: () => void
  // update: (options: Partial<LoadingOptions>) => void
}

const loadingInstances = new WeakMap<HTMLElement, LoadingInstance>()

export const vLoading: Directive<HTMLElement, boolean> = {
  mounted(el, binding) {
    // v-n-loading.fullScreen.lock
    // binding.modifiers = {full: true, lock: true}
    if (binding.value) {
      const { fullScreen = false, lock = true } = binding.modifiers
      const instance = createLoadingComponent({ fullScreen, lock })
      loadingInstances.set(el, instance)
    }
  },
  updated(el, binding) {
    if (binding.value !== binding.oldValue) {
      if (binding.value) {
        const { fullScreen = false, lock = true } = binding.modifiers
        const instance = createLoadingComponent({ fullScreen, lock })
        loadingInstances.set(el, instance)
      } else {
        const instance = loadingInstances.get(el)
        if (instance) {
          instance.close()
        }
      }
    }
  },
  unmounted(el) {
    const instance = loadingInstances.get(el)
    if (instance) {
      instance.close()
      loadingInstances.delete(el)
    }
  }
}

export const LoadingService = {
  instances: new Set<LoadingInstance>(),
  show(options?: LoadingOptions) {
    const _options = options || {
      lock: true,
      fullScreen: true
    }
    const instance = createLoadingComponent(_options)
    this.instances.add(instance)
    return instance
  },
  hide(instance?: LoadingInstance) {
    if (instance) {
      instance.close()
    } else {
      this.instances.forEach(inst => {
        inst.close()
      })
      this.instances.clear()
    }
  }
}

export function useLoading(app: App) {
  app.directive('n-loading', vLoading)
}
