import { getDeadLine } from '.'
import { ROUTER_ADDRESS } from '@/constant/contract'

// token and token
export async function addTokenLiquidity(
  owner: string,
  tokanA: string,
  tokenB: string,
  amountADesired: string,
  amountBDesired: string
) {
  const data = {
    from: owner,
    value: 0,
    contractAddress: ROUTER_ADDRESS,
    methodName: 'addLiquidity',
    methodDesc: '',
    args: [
      tokanA,
      tokenB,
      amountADesired,
      amountBDesired,
      '0',
      '0',
      owner,
      getDeadLine()
    ]
  }
  return await window.nabox.contractCall(data)
}

export async function removeTokenLiquidity(
  owner: string,
  tokanA: string,
  tokenB: string,
  liquidity: string
) {
  const data = {
    from: owner,
    value: 0,
    contractAddress: ROUTER_ADDRESS,
    methodName: 'removeLiquidity',
    methodDesc: '',
    args: [tokanA, tokenB, liquidity, '0', '0', owner, getDeadLine()]
  }
  return await window.nabox.contractCall(data)
}

// token and nuls
export async function addTokenLiquidityWithNULS(
  owner: string,
  tokenAddress: string,
  amountTokenDesired: string,
  NULSDesired: string
) {
  const data = {
    from: owner,
    value: NULSDesired,
    contractAddress: ROUTER_ADDRESS,
    methodName: 'addLiquidityNuls',
    methodDesc: '',
    args: [tokenAddress, amountTokenDesired, '0', '0', owner, getDeadLine()]
  }
  return await window.nabox.contractCall(data)
}

export async function removeTokenLiquidityWithNULS(
  owner: string,
  tokenAddress: string,
  liquidity: string
) {
  const data = {
    from: owner,
    value: 0,
    contractAddress: ROUTER_ADDRESS,
    methodName: 'removeLiquidityNuls',
    methodDesc: '',
    args: [tokenAddress, liquidity, '0', '0', owner, getDeadLine()]
  }
  return await window.nabox.contractCall(data)
}

// wasset and wasset
export async function addLiquidityWAssetAndWAsset(
  owner: string,
  wAssetAKey: string,
  wAssetBKey: string,
  wAssetAAmount: string, // 0.1
  wAssetBAmount: string // 0.1
) {
  const [chainId1, assetId1] = wAssetAKey.split('-')
  const [chainId2, assetId2] = wAssetBKey.split('-')
  const multyAssetValues = [
    [wAssetAAmount, +chainId1, +assetId1],
    [wAssetBAmount, +chainId2, +assetId2]
  ]
  const data = {
    from: owner,
    value: 0,
    contractAddress: ROUTER_ADDRESS,
    methodName: 'addLiquidityWAssetAndWAsset',
    methodDesc: '',
    args: [
      chainId1,
      assetId1,
      chainId2,
      assetId2,
      '0',
      '0',
      owner,
      getDeadLine()
    ],
    multyAssetValues
  }
  return await window.nabox.contractCall(data)
}

export async function removeLiquidityWAssetWAsset(
  owner: string,
  wAssetAKey: string,
  wAssetBKey: string,
  liquidity: string
) {
  const [chainId1, assetId1] = wAssetAKey.split('-')
  const [chainId2, assetId2] = wAssetBKey.split('-')
  const data = {
    from: owner,
    value: 0,
    contractAddress: ROUTER_ADDRESS,
    methodName: 'removeLiquidityWAssetWAsset',
    methodDesc: '',
    args: [
      chainId1,
      assetId1,
      chainId2,
      assetId2,
      liquidity,
      '0',
      '0',
      owner,
      getDeadLine()
    ]
  }
  return await window.nabox.contractCall(data)
}

// wasset and token
export async function addLiquidityWAssetToken(
  owner: string,
  wAssetKey: string,
  tokenAddress: string,
  wAssetAmount: string,
  amountTokenDesired: string
) {
  const [chainId, assetId] = wAssetKey.split('-')
  const multyAssetValues = [[wAssetAmount, +chainId, +assetId]]
  const data = {
    from: owner,
    value: 0,
    contractAddress: ROUTER_ADDRESS,
    methodName: 'addLiquidityWAsset',
    methodDesc: '',
    args: [
      chainId,
      assetId,
      tokenAddress,
      amountTokenDesired,
      '0',
      '0',
      owner,
      getDeadLine()
    ],
    multyAssetValues
  }
  return await window.nabox.contractCall(data)
}

export async function removeLiquidityWAssetToken(
  owner: string,
  wAssetKey: string,
  tokenAddress: string,
  liquidity: string
) {
  const [chainId, assetId] = wAssetKey.split('-')
  const data = {
    from: owner,
    value: 0,
    contractAddress: ROUTER_ADDRESS,
    methodName: 'removeLiquidityWAsset',
    methodDesc: '',
    args: [
      chainId,
      assetId,
      tokenAddress,
      liquidity,
      '0',
      '0',
      owner,
      getDeadLine()
    ]
  }
  return await window.nabox.contractCall(data)
}

// wasset and nuls
export async function addLiquidityWAssetNULS(
  owner: string,
  wAssetKey: string,
  wAssetAmount: string, // 0.1
  NULSDesired: string // 0.1
) {
  const [chainId, assetId] = wAssetKey.split('-')
  const multyAssetValues = [[wAssetAmount, +chainId, +assetId]]
  const data = {
    from: owner,
    value: NULSDesired,
    contractAddress: ROUTER_ADDRESS,
    methodName: 'addLiquidityNULSWAsset',
    methodDesc: '',
    args: [chainId, assetId, '0', '0', owner, getDeadLine()],
    multyAssetValues
  }
  console.log(data, 234234)
  return await window.nabox.contractCall(data)
}

export async function removeLiquidityWAssetNULS(
  owner: string,
  wAssetKey: string,
  liquidity: string
) {
  const [chainId, assetId] = wAssetKey.split('-')
  const data = {
    from: owner,
    value: 0,
    contractAddress: ROUTER_ADDRESS,
    methodName: 'removeLiquidityNulsWAsset',
    methodDesc: '',
    args: [chainId, assetId, liquidity, '0', '0', owner, getDeadLine()]
  }
  return await window.nabox.contractCall(data)
}
