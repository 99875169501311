import { onMounted, onUnmounted, watch } from 'vue'
import { useWalletStore } from '@/stores/wallet'
import { useUserStore } from '@/stores/user'

export default function useBlockData() {
  const walletStore = useWalletStore()
  const userStore = useUserStore()

  let timer: number
  const getBlockHeight = () => {
    userStore.updateBlockHeight()
  }
  function intervalBlockHeight() {
    getBlockHeight()
    timer = window.setInterval(() => {
      getBlockHeight()
    }, 10000)
  }

  function clear() {
    clearInterval(timer)
  }
  onMounted(() => {
    intervalBlockHeight()
  })
  onUnmounted(() => {
    clear()
  })

  watch(
    () => [walletStore.address, userStore.blockHeight],
    ([address, height]) => {
      if (address && height) {
        userStore.fetTokenBalances()
        userStore.updateTxs()
      }
    }
  )
}
