<template>
  <div class="space-y-6">
    <div class="mb-8">
      <h2 class="text-2xl font-bold text-white mb-2">aiNULS</h2>
      <p class="text-gray-400">
        aiNULS is a liquidity token for NULS Stake. Users can earn income from Stake and get liquid aiNULS Token at the same time.
        <br/>
        Enjoy your Stake!
      </p>
    </div>

    <div class="space-y-6">
      <StakeCard
        :stake-type="STAKE_TYPE.STAKE_NULS_EARN_NULS"
        title="Stake NULS to get aiNULS"
        description="Earn NULS rewards by staking NULS"
        :total-locked="stake_nuls_earn_nuls.locked"
        min-stake="100"
        :token="NULS"
        :nswap-apr="stake_nuls_earn_nuls.nulsApr"
        @stake="handleStakeNulsEarnNuls"
        @compound="handleStakeNulsEarnNulsCompound"
        @unstake-all="handleStakeNulsEarnNulsUnstake">
        <template v-slot:stats>
          <div class="flex justify-between items-center text-sm">
            <span class="text-gray-400">Current APR:</span>
            <span class="font-medium">
              {{ stake_nuls_earn_nuls.nulsApr || '-' }}% NULS +
              {{ stake_nuls_earn_nuls_nswapApr || '-' }}% NSWAP
            </span>
          </div>
          <div class="flex justify-between items-center text-sm">
            <span class="text-gray-400">$NULS Staked:</span>
            <span class="font-medium">
              {{ stake_nuls_earn_nuls.nulsStaked || '-' }}
            </span>
          </div>

          <div class="flex justify-between items-center text-sm">
            <span class="text-gray-400">$NULS Earned:</span>
            <span class="font-medium">
              {{ stake_nuls_earn_nuls.nulsEarned || '-' }}
            </span>
          </div>
          <div class="flex justify-between items-center text-sm">
            <span class="text-gray-400">$NSWAP Earned:</span>
            <span class="font-medium">
              {{ stake_nuls_earn_nuls.nswapEarned || '-' }}
            </span>
          </div>
        </template>
      </StakeCard>
    </div>
  </div>
</template>

<script setup lang="ts">
import StakeCard from '../stake/StakeCard.vue'
import useStateData from './hooks/useStakeData'
import { STAKE_TYPE } from '../stake/hooks/useStakeData'
import { LoadingService } from '@/components/Loading/loading-directives'
import { useUserStore } from '@/stores/user'
import { useWalletStore } from '@/stores/wallet'
import useToast from '@/hooks/useToast'
import {
  stakeNulsEarnNuls,
  stakeNulsEarnNulsCompound,
  stakeNulsEarnNulsUnstake,
} from '@/utils/nuls/stake'

const walletStore = useWalletStore()
const userStore = useUserStore()
const { toastSuccess, toastError } = useToast()

const {
  stake_nuls_earn_nuls,
  NULS,
  stake_nuls_earn_nuls_nswapApr
} = useStateData()

/*-------------------stake nuls earn nuls-------------------*/
const handleStakeNulsEarnNuls = async (amount: string) => {
  LoadingService.show()
  try {
    const hash = await stakeNulsEarnNuls(
      walletStore.address,
      amount,
      NULS.value!.decimals
    )
    if (hash) {
      toastSuccess('Success')
      const tx = {
        summary: `Stake ${amount} NULS`,
        hash,
        time: new Date().getTime() + '',
        status: 0 as const
      }
      userStore.addTx(tx)
    }
  } catch (e) {
    toastError(e)
  }
  LoadingService.hide()
}
const handleStakeNulsEarnNulsCompound = async () => {
  LoadingService.show()
  try {
    const hash = await stakeNulsEarnNulsCompound(walletStore.address)
    if (hash) {
      toastSuccess('Success')
      const tx = {
        summary: `Compound NULS`,
        hash,
        time: new Date().getTime() + '',
        status: 0 as const
      }
      userStore.addTx(tx)
    }
  } catch (e) {
    toastError(e)
  }
  LoadingService.hide()
}
const handleStakeNulsEarnNulsUnstake = async () => {
  LoadingService.show()
  try {
    const hash = await stakeNulsEarnNulsUnstake(walletStore.address)
    if (hash) {
      toastSuccess('Success')
      const tx = {
        summary: `Withawal NULS`,
        hash,
        time: new Date().getTime() + '',
        status: 0 as const
      }
      userStore.addTx(tx)
    }
  } catch (e) {
    toastError(e)
  }
  LoadingService.hide()
}
</script>
