<template>
  <div class="max-w-md mx-auto">
    <div class="card p-6 max-w-[480px] mx-auto">
      <SwapHeader
        v-model:show-history="showHistory"
        v-model:show-settings="showSettings"
        @refresh="handleRefresh" />

      <div class="space-y-3">
        <TokenInput
          label="From"
          :address="walletStore.address"
          :token="tokenA"
          :value="amountA"
          @change="changeAmountA"
          :show-percentages="!!Number(tokenA.balance)"
          @select-token="handleShowTokenList('A')"
          @select-percentage="
            (percent: number) => selectPercentage(percent, 'A')
          " />

        <SwapArrow @click="switchTokens" />

        <TokenInput
          label="To"
          :address="walletStore.address"
          :token="tokenB"
          :value="amountB"
          @change="changeAmountB"
          @select-token="handleShowTokenList('B')"
          @select-percentage="
            (percent: number) => selectPercentage(percent, 'B')
          " />
      </div>

      <SwapInfo
        v-if="tradeInfo"
        class="mt-4 mb-4"
        :isExactIn="isExactIn"
        :tradePrice="tradePrice"
        :receive-or-sold-amount="receiveOrSoldAmount"
        :price-impact="priceImpactInfo"
        :realizedLPFee="tradeInfo.realizedLPFee!"
        :fromSymbol="tokenA.symbol"
        :to-symbol="tokenB.symbol"
        :route="tradeInfo!.route.path"
        @toggle-inverted="showInvertedPrice = !showInvertedPrice"
        @show-route="showRouteModal = true"
        @show-setting="showSettings = true" />

      <AuthButton
        class="w-full mt-4"
        size="lg"
        :disabled="!canSupply"
        @click="handleConfirm">
        {{ supplyButtonText }}
      </AuthButton>

      <TokenListModal
        :show="showTokenList"
        @close="showTokenList = false"
        @select="handleSelectToken" />

      <!-- <SwapRouteModal
        :show="showRouteModal"
        :route="tradeInfo!.route.path"
        @close="showRouteModal = false" /> -->

      <SwapSettingsModal :show="showSettings" @close="showSettings = false" />

      <SwapHistoryModal :show="showHistory" @close="showHistory = false" />

      <!-- <SwapModals
        :show-from-list="showFromTokenList"
        :show-to-list="showToTokenList"
        :show-route="showRouteModal"
        :show-settings="showSettings"
        :show-history="showHistory"
        :from-token="fromToken"
        :to-token="toToken"
        @close-from-list="showFromTokenList = false"
        @close-to-list="showToTokenList = false"
        @close-route="showRouteModal = false"
        @close-settings="showSettings = false"
        @close-history="showHistory = false"
        @select-from-token="selectFromToken"
        @select-to-token="selectToToken" /> -->
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import AuthButton from '@/components/wallet/AuthButton.vue'
import SwapHeader from './SwapHeader.vue'
import TokenInput from './TokenInput.vue'
import SwapArrow from './SwapArrow.vue'
import SwapInfo from './SwapInfo.vue'
import TokenListModal from './modals/TokenListModal.vue'
// import SwapRouteModal from './modals/SwapRouteModal.vue'
import SwapSettingsModal from './modals/SwapSettingsModal.vue'
import SwapHistoryModal from './modals/SwapHistoryModal.vue'
import useTokens from '../pools/hooks/useTokens'
import { useTokenAllowance } from '../pools/hooks/useAllowance'
import useTradeInfo from './hooks/useTradeInfo'
import useTrade from './hooks/useTrade'
import { IToken, useUserStore } from '@/stores/user'
import { useWalletStore } from '@/stores/wallet'
import { Times, Division, fixNumber, timesDecimals, Plus } from '@/utils/utils'
import { warningSeverity } from '@/utils/exchange'
import { MINIMUM_NULS, ROUTER_ADDRESS } from '@/constant/contract'
import { LoadingService } from '@/components/Loading/loading-directives'

const userStore = useUserStore()
const walletStore = useWalletStore()

const {
  amountA,
  amountB,
  tokenA,
  tokenB,
  selectToken,
  tokenType,
  insufficientTokenA,
  isNULSLessThanMinimum
} = useTokens('/swap')

const { tokenNeedApprove, approveAllowance, allowanceLoading } =
  useTokenAllowance(tokenA, amountA, ROUTER_ADDRESS)

const {
  isExactIn,
  tradeInfo,
  platformFee,
  showInvertedPrice,
  tradePrice,
  receiveOrSoldAmount,
  priceImpactInfo
} = useTradeInfo(tokenA, tokenB, amountA, amountB)

const { swap } = useTrade(tokenA, tokenB, amountA, amountB)

const showTokenList = ref(false)

const handleShowTokenList = (dir: 'A' | 'B') => {
  tokenType.value = dir
  showTokenList.value = true
}

const changeAmountA = (val: string) => {
  isExactIn.value = true
  amountA.value = val
  amountB.value = tradeInfo.value?.outputAmount.toExact() || ''
}
const changeAmountB = (val: string) => {
  isExactIn.value = false
  amountB.value = val
  const extraFee = Plus(10000, platformFee.value).toFixed()
  amountA.value =
    tradeInfo.value?.inputAmount.multiply(extraFee).divide(10000).toExact() || ''
}

watch(
  () => tradeInfo.value,
  val => {
    if (!val) return
    if (isExactIn.value) {
      changeAmountA(amountA.value)
    } else {
      changeAmountB(amountB.value)
    }
  }
)

const handleRefresh = () => {
  userStore.changeRefresh()
}

const handleSelectToken = (token: IToken) => {
  selectToken(token)
  amountA.value = ''
  amountB.value = ''
}

const selectPercentage = (percent: number, dir: 'A' | 'B') => {
  const { balance, decimals } = dir === 'A' ? tokenA.value : tokenB.value
  const amount = fixNumber(
    Division(Times(balance || '0', percent), 100).toFixed(),
    decimals
  )
  if (dir === 'A') {
    changeAmountA(amount)
  } else {
    changeAmountB(amount)
  }
}

const showRouteModal = ref(false)
const showSettings = ref(false)
const showHistory = ref(false)

const switchTokens = () => {
  selectToken(tokenType.value === 'A' ? tokenB.value : tokenA.value)

  if (isExactIn.value) {
    changeAmountB(amountA.value)
  } else {
    changeAmountA(amountB.value)
  }
}

const canSupply = computed(() => {
  return (
    amountA.value !== '' &&
    amountB.value !== '' &&
    !allowanceLoading.value &&
    tradeInfo.value &&
    !insufficientTokenA.value &&
    !isNULSLessThanMinimum.value
  )
})

const supplyButtonText = computed(() => {
  if (!amountA.value && !amountB.value) return 'Enter an amount'
  if (insufficientTokenA.value) return `Insufficient ${tokenA.value.symbol}`
  if (isNULSLessThanMinimum.value) return `Minimum NULS is ${MINIMUM_NULS}`
  if ((amountA.value || amountB.value) && !tradeInfo.value)
    return 'Insufficient liquidity'
  if (tokenNeedApprove.value) return `Approve ${tokenA.value.symbol}`
  const severity = warningSeverity(tradeInfo.value?.priceImpactWithoutFee)
  if (severity > 3) {
    return 'Price Impact High'
  } else if (severity > 2) {
    return 'Swap Anyway'
  } else {
    return 'Swap'
  }
})

const handleConfirm = async () => {
  LoadingService.show()
  if (tokenNeedApprove.value) {
    await approveAllowance(
      tokenA.value.address,
      timesDecimals(amountA.value, tokenA.value.decimals)
    )
  } else {
    const route = tradeInfo.value?.route.path.map(v => v.address || v.chainId)
    const success = await swap(
      isExactIn.value,
      receiveOrSoldAmount.value,
      route
    )
    if (success) {
      amountA.value = ''
      amountB.value = ''
    }
  }
  LoadingService.hide()
}
</script>
