<template>
  <BaseModal :show="show" @close="closeModal">
    <div class="modal-wrap">
      <div class="flex justify-between items-center mb-6">
        <h3 class="text-xl font-bold text-white">Unstake NSWAP</h3>
        <button class="text-gray-400 hover:text-white" @click="closeModal">
          <x-mark-icon class="h-5 w-5" />
        </button>
      </div>

      <div class="space-y-2">
        <h3 class="text-[22px] font-bold">
          Getting Ready to Unstake Your $<span class="text-primary">NSWAP</span>?
        </h3>
        <div class="text-sm space-y-2">
          <p>Here's what you need to know:</p>
          <p>
            If you've staked in the last
            <span class="text-primary">21 days</span>, hold on a bit longer to
            unstake your $NSWAP.
          </p>
          <p>
            If you're staking $aNSWAP on other platforms, start by unstaking
            there.
          </p>
          <p>Enjoy your well-earned rewards and keep staking happily!</p>
        </div>
      </div>

      <div class="flex justify-center mt-6">
        <Button class="w-60" @click="emit('unstake')">UNSTAKE</Button>
      </div>
    </div>
  </BaseModal>
</template>

<script setup lang="ts">
import { XMarkIcon, InformationCircleIcon } from '@heroicons/vue/24/outline'
import BaseModal from '@/components/ui/BaseModal.vue'
import Button from '@/components/ui/Button.vue'

defineProps<{
  show: boolean
}>()

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'unstake'): void
}>()

const closeModal = () => {
  emit('close')
}
</script>
