<template>
  <BaseModal :show="show" @close="closeModal" max-width="md">
    <div class="modal-wrap">
      <div class="flex items-center mb-6">
        <button
          class="p-2 -ml-2 rounded-lg text-gray-400 hover:text-white hover:bg-gray-700/50 transition-all duration-200"
          @click="closeModal">
          <arrow-left-icon class="h-5 w-5" />
        </button>
        <div class="ml-2">
          <h3 class="text-xl font-bold text-white">
            {{ tokenA.symbol + '-' + tokenB.symbol }} LP
          </h3>
          <!-- <p class="text-sm text-gray-400">
            Receive LP tokens and earn 0.3% trading fees
          </p> -->
        </div>
      </div>

      <div class="space-y-4">
        <TokenInput
          :address="walletStore.address"
          :token="tokenA"
          :value="amountA"
          @change="changeAmountA"
          :show-percentages="!!Number(tokenA.balance)"
          @select-token="handleShowTokenList('A')"
          @select-percentage="
            (percent: number) => selectPercentage(percent, 'A')
          " />

        <div class="flex justify-center">
          <plus-icon class="h-5 w-5 text-gray-400" />
        </div>

        <TokenInput
          :address="walletStore.address"
          :token="tokenB"
          :value="amountB"
          @change="changeAmountB"
          :show-percentages="!!Number(tokenA.balance)"
          @select-token="handleShowTokenList('B')"
          @select-percentage="
            (percent: number) => selectPercentage(percent, 'B')
          " />
      </div>

      <div class="mt-6 p-4 rounded-xl bg-gray-900/50">
        <h4 class="text-white font-medium mb-4">Prices and Share</h4>
        <div class="space-y-2">
          <div class="flex justify-between">
            <span class="text-gray-400"
              >{{ tokenB.symbol }} per {{ tokenA.symbol }}</span
            >
            <span class="text-white">{{ price?.toSignificant(6) ?? '-' }}</span>
          </div>
          <div class="flex justify-between">
            <span class="text-gray-400"
              >{{ tokenA.symbol }} per {{ tokenB.symbol }}</span
            >
            <span class="text-white">{{
              price?.invert()?.toSignificant(6) ?? '-'
            }}</span>
          </div>
          <div class="flex justify-between">
            <span class="text-gray-400">Share in Trading Pair</span>
            <span class="text-white">
              {{
                noLiquidity && price
                  ? '100'
                  : ((poolTokenPercentage?.lessThan(ONE_BIPS)
                      ? '<0.01'
                      : poolTokenPercentage?.toFixed(2)) ?? '0')
              }}%
            </span>
          </div>
        </div>
      </div>

      <AuthButton
        class="w-full mt-4"
        size="lg"
        :disabled="!canSupply"
        @click="handleConfirm">
        {{ supplyButtonText }}
      </AuthButton>
      <!-- <button
        class="w-full px-4 py-3 rounded-xl bg-primary text-white hover:bg-primary-dark transition-all duration-200 mt-6"
        :class="{ 'opacity-50 cursor-not-allowed': !canSupply }"
        :disabled="!canSupply"
        @click="handleConfirm">
        {{ supplyButtonText }}
      </button> -->

      <p class="mt-6 text-sm text-gray-400 text-center">
        By adding liquidity you'll earn 0.3% of all trades on this pair
        proportional to your share in the trading pair. Fees are added to your
        share in the pair, accrue in real time and can be claimed by withdrawing
        your liquidity.
      </p>
    </div>

    <TokenListModal
      :show="showTokenList"
      :only-base-tokens="showOnluBaseTokens"
      @close="showTokenList = false"
      @select="handleSelectToken" />
  </BaseModal>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { ArrowLeftIcon, PlusIcon } from '@heroicons/vue/24/outline'
import BaseModal from '@/components/ui/BaseModal.vue'
import TokenInput from '@/views/swap/TokenInput.vue'
import TokenListModal from '@/views/swap/modals/TokenListModal.vue'
import AuthButton from '@/components/wallet/AuthButton.vue'
import { Times, Division, fixNumber, timesDecimals } from '@/utils/utils'
import { IToken } from '@/stores/user'
import { useWalletStore } from '@/stores/wallet'
import { useUserStore } from '@/stores/user'
import useTokens from '../hooks/useTokens'
import useAllowance from '../hooks/useAllowance'
import useAddLiquidity from '../hooks/useAddLiquidity'
import { ONE_BIPS } from '@/constant/exchange'
import { MINIMUM_NULS, ROUTER_ADDRESS } from '@/constant/contract'
import { LoadingService } from '@/components/Loading/loading-directives'

defineProps<{
  show: boolean
}>()

const emit = defineEmits<{
  (e: 'close'): void
}>()

const walletStore = useWalletStore()
const userStore = useUserStore()

const {
  amountA,
  amountB,
  tokenA,
  tokenB,
  selectToken,
  tokenType,
  insufficientTokenA,
  insufficientTokenB,
  isNULSLessThanMinimum
} = useTokens('/pools')

const {
  tokenANeedApprove,
  tokenBNeedApprove,
  approveAllowance,
  allowanceLoading
} = useAllowance(tokenA, tokenB, amountA, amountB, ROUTER_ADDRESS)

const {
  isExactA,
  price,
  noLiquidity,
  poolTokenPercentage,
  formatAmountA,
  formatAmountB,
  addLiquidity
} = useAddLiquidity(tokenA, tokenB, amountA, amountB)

const showTokenList = ref(false)

const handleShowTokenList = (dir: 'A' | 'B') => {
  tokenType.value = dir
  showTokenList.value = true
}

const changeAmountA = (val: string) => {
  isExactA.value = true
  formatAmountB(val)
}
const changeAmountB = (val: string) => {
  isExactA.value = false
  formatAmountA(val)
}

const canSupply = computed(() => {
  return (
    amountA.value !== '' &&
    amountB.value !== '' &&
    !allowanceLoading.value &&
    !insufficientTokenA.value &&
    !insufficientTokenB.value &&
    !isNULSLessThanMinimum.value &&
    !InvalidPair.value
  )
})

const InvalidPair = computed(() => {
  const BaseTokenKeys = userStore.baseTokensKeys
  return (
    !BaseTokenKeys.includes(tokenB.value.assetKey) &&
    !BaseTokenKeys.includes(tokenA.value.assetKey)
  )
})

const supplyButtonText = computed(() => {
  if (!amountA.value || !amountB.value) return 'Enter an amount'
  if (insufficientTokenA.value) return `Insufficient ${tokenA.value.symbol}`
  if (insufficientTokenB.value) return `Insufficient ${tokenB.value.symbol}`
  if (isNULSLessThanMinimum.value) return `Minimum NULS is ${MINIMUM_NULS}`
  if (InvalidPair.value) {
    return 'Invalid Pair'
  }
  if (tokenANeedApprove.value) return `Approve ${tokenA.value.symbol}`
  if (tokenBNeedApprove.value) return `Approve ${tokenB.value.symbol}`
  return 'Supply'
})

const showOnluBaseTokens = computed(() => {
  const BaseTokenKeys = userStore.baseTokensKeys
  if (tokenType.value === 'A') {
    return !BaseTokenKeys.includes(tokenB.value.assetKey)
  } else {
    return !BaseTokenKeys.includes(tokenA.value.assetKey)
  }
})

const handleSelectToken = (token: IToken) => {
  selectToken(token)
  amountA.value = ''
  amountB.value = ''
}

const selectPercentage = (percent: number, dir: 'A' | 'B') => {
  const { balance, decimals } = dir === 'A' ? tokenA.value : tokenB.value
  const amount = fixNumber(
    Division(Times(balance || '0', percent), 100).toFixed(),
    decimals
  )
  if (dir === 'A') {
    changeAmountA(amount)
  } else {
    changeAmountB(amount)
  }
}

const handleConfirm = async () => {
  LoadingService.show()
  if (tokenANeedApprove.value) {
    await approveAllowance(
      tokenA.value.address,
      timesDecimals(amountA.value, tokenA.value.decimals)
    )
  } else if (tokenBNeedApprove.value) {
    await approveAllowance(
      tokenB.value.address,
      timesDecimals(amountB.value, tokenB.value.decimals)
    )
  } else {
    await addLiquidity()
    closeModal()
  }
  LoadingService.hide()
}

const closeModal = () => {
  amountA.value = ''
  amountB.value = ''
  emit('close')
}
</script>
