<template>
  <div class="fixed bottom-0 left-0 w-full h-14 lg:hidden">
    <nav
      class="h-full bg-gray-800 backdrop-blur-sm border-t border-gray-700/50">
      <div class="flex items-center px-4 py-1 h-full">
        <div
          class="flex-1 text-center"
          v-for="item in navigation"
          :key="item.name">
          <router-link
            :to="item.href"
            class="nav-link group relative px-4 py-2 rounded-xl text-sm font-medium transition-all duration-300"
            :class="[
              isCurrentRoute(item.href)
                ? 'text-primary'
                : 'text-gray-300 hover:text-white'
            ]">
            <span class="relative z-10">{{ item.name }}</span>
            <div
              class="absolute bottom-0 left-1/2 h-0.5 bg-primary transform -translate-x-1/2 transition-all duration-300 w-0 group-hover:w-4/5"
              :class="{ 'w-4/5': isCurrentRoute(item.href) }"></div>
          </router-link>
        </div>
      </div>
    </nav>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()

const navigation = ref([
  { name: 'Swap', href: '/swap' },
  { name: 'Pools', href: '/pools' },
  { name: 'Stake', href: '/stake' },
  { name: 'aiNULS', href: '/ainuls' },
])

const isCurrentRoute = (path: string) => {
  return route.path.includes(path)
}
</script>
