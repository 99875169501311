<template>
  <div class="space-y-6">
    <div>
      <h2 class="text-2xl font-bold text-white mb-1">Your Liquidity</h2>
      <p class="text-gray-400">Add liquidity to earn 0.3% LP rewards</p>
    </div>

    <PoolsList @add="handleAdd" @remove="handleRemove" />

    <button
      class="w-full px-4 py-3 rounded-xl bg-primary text-white hover:bg-primary-dark transition-all duration-200 flex items-center justify-center gap-2"
      @click="showAddLiquidity = true">
      <plus-icon class="h-5 w-5" />
      Add Liquidity
    </button>

    <AddLiquidityModal
      v-if="showAddLiquidity"
      :show="showAddLiquidity"
      @close="showAddLiquidity = false" />
    <RemoveLiquidityModal
      v-if="showRemoveLiquidity"
      :show="showRemoveLiquidity"
      :lp="removeLPItem"
      @close="showRemoveLiquidity = false" />
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { PlusIcon } from '@heroicons/vue/24/outline'
import PoolsList from './PoolsList.vue'
import AddLiquidityModal from './modals/AddLiquidityModal.vue'
import RemoveLiquidityModal from './modals/RemoveLiquidityModal.vue'
import { ILP } from './hooks/useUserLPs'

const router = useRouter()

const showAddLiquidity = ref(false)
const showRemoveLiquidity = ref(false)
const removeLPItem = ref<ILP>({} as ILP)

watch(showAddLiquidity, val => {
  if (!val) {
    router.replace('/pools')
  }
})

const handleAdd = (lp: ILP) => {
  router.replace(`/pools/${lp.tokenA.assetKey}/${lp.tokenB.assetKey}`)
  showAddLiquidity.value = true
}
const handleRemove = (lp: ILP) => {
  console.log(lp, 234)
  removeLPItem.value = lp
  showRemoveLiquidity.value = true
}
</script>
