<template>
  <div class="mb-6">
    <div class="flex justify-between items-center mb-2">
      <div>
        <h2 class="text-2xl font-bold text-white mb-1">Swap</h2>
        <p class="text-gray-400 text-sm">Trade tokens in an instant</p>
      </div>
      <div class="flex items-center space-x-2">
        <button
          class="text-gray-400 hover:text-white p-2 rounded-lg hover:bg-gray-700/50 transition-all duration-200"
          title="Transaction Settings"
          @click="emit('update:showSettings', true)">
          <Cog6ToothIcon class="h-5 w-5" />
        </button>
        <button
          class="text-gray-400 hover:text-white p-2 rounded-lg hover:bg-gray-700/50 transition-all duration-200"
          title="Recent Transactions"
          @click="emit('update:showHistory', true)">
          <ClockIcon class="h-5 w-5" />
        </button>
        <button
          class="text-gray-400 hover:text-white p-2 rounded-lg hover:bg-gray-700/50 transition-all duration-200"
          title="Refresh"
          @click="doRefresh">
          <ArrowPathIcon :class="['h-5', 'w-5', refreshing && 'refreshing']" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import {
  Cog6ToothIcon,
  ClockIcon,
  ArrowPathIcon
} from '@heroicons/vue/24/outline'

defineProps<{
  showSettings: boolean
  showHistory: boolean
}>()

const emit = defineEmits<{
  (e: 'update:showSettings', showSettings: boolean): void
  (e: 'update:showHistory', showHistory: boolean): void
  (e: 'refresh'): void
}>()

// const showSettings = ref(false)
// const showHistory = ref(false)

const refreshing = ref(false)
const doRefresh = () => {
  if (refreshing.value) return
  refreshing.value = true
  emit('refresh')
  setTimeout(() => {
    refreshing.value = false
  }, 1500)
}
</script>

<style scoped>
.refreshing {
  transform-origin: center center;
  animation: beRotate 1.5s linear infinite;
}
@keyframes beRotate {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
