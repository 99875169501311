import { MULTICALL_ADDRESS, ROUTER_ADDRESS } from '@/constant/contract'
import { Division } from '../utils'
import { invokeView } from '@/service/api'

export async function getPairsInfo(
  pairAddress: string[]
): Promise<[string, string][]> {
  const data = {
    contractAddress: MULTICALL_ADDRESS,
    methodName: 'aggregateStrict',
    methodDesc: '',
    args: [
      pairAddress,
      pairAddress.map(_ => 'getReserves'),
      pairAddress.map(_ => ''),
      false
    ]
  }
  
  const res = await invokeView(data)
  // console.log(res, 'getPairsInfo', pairAddress)
  if (!res.result) return []
  const result = JSON.parse(res.result)
  return result.map((v: string) => v.split(','))
}

export async function getUserLPs(pairAddress: string[], userAddress: string) {
  const data = {
    contractAddress: MULTICALL_ADDRESS,
    methodName: 'aggregateStrict',
    methodDesc: '',
    args: [
      pairAddress,
      pairAddress.map(_ => 'getLPBalance'),
      pairAddress.map(_ => userAddress),
      false
    ]
  }
  const res = await invokeView(data)
  if (!res.result) return []
  // console.log(res.result, 'getUserLPs')
  return JSON.parse(res.result)
}

export async function getPairsLPAddresses(pairAddress: string[]) {
  const data = {
    contractAddress: MULTICALL_ADDRESS,
    methodName: 'aggregateStrict',
    methodDesc: '',
    args: [
      pairAddress,
      pairAddress.map(_ => 'getLP'),
      pairAddress.map(_ => ''),
      false
    ]
  }
  const res = await invokeView(data)
  if (!res.result) return []
  // console.log(res.result, 'getPairsLPAddresses')
  return JSON.parse(res.result)
}

export async function getLPTotalSupply(lpAddresses: string[]) {
  const data = {
    contractAddress: MULTICALL_ADDRESS,
    methodName: 'aggregateStrict',
    methodDesc: '',
    args: [
      lpAddresses,
      lpAddresses.map(_ => 'totalSupply'),
      lpAddresses.map(_ => ''),
      false
    ]
  }
  const res = await invokeView(data)
  if (!res.result) return []
  // console.log(res.result, 'getPairsLPs')
  return JSON.parse(res.result)
}

export async function getPairLPAddress(pairAddress: string) {
  const { result } = await invokeView({
    contractAddress: pairAddress,
    methodName: 'getLP',
    methodDesc: '',
    args: []
  })
  return result || ''
}

export async function getPairTotalSupply(pairAddress: string) {
  const LPAddress = await getPairLPAddress(pairAddress)
  const res = await window.nabox.invokeView({
    contractAddress: LPAddress,
    methodName: 'totalSupply',
    methodDesc: '',
    args: []
  })

  // console.log(res, 'getPairTotalSupply--getPairTotalSupply')
  return res.result || '0'
}

export async function getPlatformFee() {
  const res = await window.nabox.invokeView({
    contractAddress: ROUTER_ADDRESS,
    methodName: 'getPlatformFee',
    methodDesc: '',
    args: []
  })
  // 1%%
  // console.log(res, 'getPlatformFee')
  const _result = res.result || '100'
  return _result
  // return Division(_result, 100).toFixed() || '0'
}
