import { ref, Ref, watch } from 'vue'
import { CurrencyAmount, Pair, ERC20Token } from '@/swap-sdk'
import { getPairsInfo } from '@/utils/nuls/multiCall'
import { IToken, useUserStore } from '@/stores/user'

export default function usePairs() {}

export function usePair(tokenA: Ref<IToken>, tokenB: Ref<IToken>) {
  const userStore = useUserStore()
  const pairInfo = ref<Pair | null>()

  const fetchInfo = (tokenA: IToken, tokenB: IToken) => {
    const pair = [[tokenA, tokenB]] as [IToken, IToken][]
    handleGetPairInfo(pair).then(res => {
      pairInfo.value = res[0]
    })
  }

  let timer: number = 0
  watch(
    [() => tokenA.value, () => tokenB.value, () => userStore.blockHeight],
    ([tokenA, tokenB, height], [_tokenA, _tokenB]) => {
      if (!tokenA || !tokenB || !height) {
        return
      }
      clearTimeout(timer)
      if (
        tokenA.assetKey !== _tokenA?.assetKey ||
        tokenB.assetKey !== _tokenB?.assetKey
      ) {
        pairInfo.value = null
      }
      timer = window.setTimeout(() => {
        fetchInfo(tokenA, tokenB)
      }, 100)
    },
    { immediate: true }
  )

  return pairInfo
}

export async function handleGetPairInfo(
  currencies: [IToken, IToken][]
): Promise<(Pair | null)[]> {
  const tokens = currencies.map(([currencyA, currencyB]) => [
    new ERC20Token(
      currencyA.assetKey,
      currencyA.address,
      currencyA.decimals,
      currencyA.symbol
    ),
    new ERC20Token(
      currencyB.assetKey,
      currencyB.address,
      currencyB.decimals,
      currencyB.symbol
    )
  ])

  const pairAddresses = tokens.map(([tokenA, tokenB]) => {
    try {
      return tokenA && tokenB && !tokenA.equals(tokenB)
        ? Pair.getAddress(tokenA, tokenB)
        : undefined
    } catch (e) {
      return undefined
    }
  }) as string[]

  const results = await getPairsInfo(pairAddresses)
  return results.map((result, i) => {
    const [reserve0, reserve1] = result
    if (!reserve0 || !reserve1) return null
    const tokenA = tokens[i][0]
    const tokenB = tokens[i][1]

    if (!tokenA || !tokenB || tokenA.equals(tokenB)) return null
    if (!result.length) return null
    const [token0, token1] = tokenA.sortsBefore(tokenB)
      ? [tokenA, tokenB]
      : [tokenB, tokenA]
    return new Pair(
      CurrencyAmount.fromRawAmount(token0, reserve0.toString()),
      CurrencyAmount.fromRawAmount(token1, reserve1.toString())
    )
  })
}
