import { ref, reactive, computed, watch, onMounted } from 'vue'
import { useWalletStore } from '@/stores/wallet'
import { useUserStore } from '@/stores/user'
import { getNULSApr } from '@/service/api'
import {
  getAINULSLocked,
  getStakeNULSEarnNULSData,
  calcPairAddress,
  getPairsInfo
} from '@/utils/nuls'
import { divisionAndFix, Division, fixNumber } from '@/utils/utils'
import { NULSKey, NSWAPKey } from '@/constant/contract'

export default function useStateData() {
  const walletStore = useWalletStore()
  const userStore = useUserStore()

  const stake_nuls_earn_nuls = reactive({
    locked: '0',
    nulsApr: '',
    nswapArp: '',
    nulsStaked: '',
    nulsEarned: '',
    nswapEarned: ''
  })

  const nuls_nswap_reserves = ref([''])

  onMounted(() => {
    getNULSApr().then(res => {
      stake_nuls_earn_nuls.nulsApr = res
    })
  })

  const fetchData = () => {
    getAINULSLocked().then(res => {
      const [locked1] = res
      stake_nuls_earn_nuls.locked = divisionAndFix(
        locked1,
        NULS.value?.decimals,
        2
      )
      const NULS_NSWAP_Pair = calcPairAddress(NULS.value!, NSWAP.value!)
      getPairsInfo([NULS_NSWAP_Pair]).then(res => {
        const [reserve0, reserve1] = res[0]
        nuls_nswap_reserves.value = [reserve0, reserve1]
      })
    })
    if (walletStore.address) {
      getStakeNULSEarnNULSData(walletStore.address).then(res => {
        // console.log(res, 'getStakeNULSEarnNULSData')
        const [nulsStaked, nulsEarned, nswapEarned] = res
        stake_nuls_earn_nuls.nulsStaked = nulsStaked
          ? divisionAndFix(nulsStaked, NULS.value?.decimals, 2)
          : ''
        stake_nuls_earn_nuls.nulsEarned = nulsEarned
          ? divisionAndFix(nulsEarned, NULS.value?.decimals, 2)
          : ''
        stake_nuls_earn_nuls.nswapEarned = nswapEarned
          ? divisionAndFix(nswapEarned, NSWAP.value?.decimals, 2)
          : ''
      })
    }
  }
  watch(
    () => userStore.blockHeight,
    () => {
      fetchData()
    },
    { immediate: true }
  )

  watch(
    () => walletStore.address,
    () => {
      fetchData()
    }
  )

  const NULS = computed(() => {
    return userStore.tokenWithBalances.find(v => v.assetKey === NULSKey)
  })
  const NSWAP = computed(() => {
    return userStore.tokenWithBalances.find(v => v.assetKey === NSWAPKey)
  })

  const stake_nuls_earn_nuls_nswapApr = computed(() => {
    const [nulsReserve, nswapReserve] = nuls_nswap_reserves.value
    if (!nulsReserve || !nswapReserve || !stake_nuls_earn_nuls.locked) return ''
    return fixNumber(
      Division(
        Division(nulsReserve, nswapReserve),
        stake_nuls_earn_nuls.locked
      ).toFixed(),
      2
    )
  })

  return {
    stake_nuls_earn_nuls,
    NULS,
    NSWAP,
    stake_nuls_earn_nuls_nswapApr
  }
}
