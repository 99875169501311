import { Percent } from '@/swap-sdk-core'
import { ERC20Token } from './entities/token'

export enum ChainId {
  ETHEREUM = '1',
  GOERLI = '119',
  BSC = '56',
  BSC_TESTNET = '120',
  ZKSYNC_TESTNET = '280',
  LINEA_GOERLI = '59140',
  LINEA = '59144',
  BASE = '8453'
}

export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')

export const FACTORY_ADDRESS = '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73'

const FACTORY_ADDRESS_ETH = '0x1097053Fd2ea711dad45caCcc45EfF7548fCB362'

export const FACTORY_ADDRESS_MAP: Record<number, string> = {
  [ChainId.ETHEREUM]: FACTORY_ADDRESS_ETH,
  [ChainId.GOERLI]: '0x7bf960B15Cbd9976042257Be3F6Bb2361E107384',
  [ChainId.BSC]: FACTORY_ADDRESS,
  [ChainId.BSC_TESTNET]: '0xaFA293c81912aDAAB53B1fB409dA5D072661BcAB',
  [ChainId.ZKSYNC_TESTNET]: '0xd98CAB3D6a7166de275C33a2Cc75Ee02BE4D3799',
  [ChainId.LINEA_GOERLI]: '0xd98CAB3D6a7166de275C33a2Cc75Ee02BE4D3799',
  [ChainId.LINEA]: '0x7bf960B15Cbd9976042257Be3F6Bb2361E107384',
  [ChainId.BASE]: '0x7bf960B15Cbd9976042257Be3F6Bb2361E107384',
}
export const INIT_CODE_HASH = '0x00fb7f630766e6a796048ea87d01acd3068e8ff67d078148a3fa3f4a84f69bd5'

const INIT_CODE_HASH_ETH = '0x57224589c67f3f30a6b0d7a1b54cf3153ab84563bc609ef41dfb34f8b2974d2d'
export const INIT_CODE_HASH_MAP: Record<number, string> = {
  [ChainId.ETHEREUM]: INIT_CODE_HASH_ETH,
  [ChainId.GOERLI]: '0xc70f379f5b8a328cc6a6260d6d2391a9d001671af6e298516835de119ada32a1',
  [ChainId.BSC]: INIT_CODE_HASH,
  [ChainId.BSC_TESTNET]: '0x8250fbeb8b7880978bc95b8230b74313f1d443887214d734e39d3df6bd3a002a',
  [ChainId.ZKSYNC_TESTNET]: '0xbf203230e66fd90a9049c2c6ebcd292d2e615c914af3ee0a37a625c3c39e9030',
  [ChainId.LINEA_GOERLI]: '0xbf203230e66fd90a9049c2c6ebcd292d2e615c914af3ee0a37a625c3c39e9030',
  [ChainId.LINEA]: '0xc70f379f5b8a328cc6a6260d6d2391a9d001671af6e298516835de119ada32a1',
  [ChainId.BASE]: '0x9e90c2fa176fd0486a2f1a2e0396d09ce6028a6ce326d173e7964b6cd0588b4a',
}

export const WETH9 = {
  [ChainId.ETHEREUM]: new ERC20Token(
    ChainId.ETHEREUM,
    '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    18,
    'WETH',
    'Wrapped Ether',
    'https://weth.io'
  ),
  [ChainId.GOERLI]: new ERC20Token(
    ChainId.GOERLI,
    '0x217dffF57E3b855803CE88a1374C90759Ea071bD',
    18,
    'WNULS',
    'Wrapped NULS',
    'https://nuls.io'
  ),
  [ChainId.ZKSYNC_TESTNET]: new ERC20Token(
    ChainId.ZKSYNC_TESTNET,
    '0x20b28B1e4665FFf290650586ad76E977EAb90c5D',
    18,
    'WETH',
    'Wrapped ETH',
    'https://weth.io'
  ),
  [ChainId.LINEA_GOERLI]: new ERC20Token(
    ChainId.LINEA_GOERLI,
    '0x20b28B1e4665FFf290650586ad76E977EAb90c5D',
    18,
    'WETH',
    'Wrapped ETH',
    'https://weth.io'
  ),
  [ChainId.LINEA]: new ERC20Token(
    ChainId.LINEA,
    '0xe5D7C2a44FfDDf6b295A15c148167daaAf5Cf34f',
    18,
    'WETH',
    'Wrapped ETH',
    'https://weth.io'
  ),
  [ChainId.BASE]: new ERC20Token(
    ChainId.BASE,
    '0x4200000000000000000000000000000000000006',
    18,
    'WETH',
    'Wrapped ETH',
    'https://weth.io'
  ),
}

export const WBNB = {
  [ChainId.ETHEREUM]: new ERC20Token(
    ChainId.ETHEREUM,
    '0x418D75f65a02b3D53B2418FB8E1fe493759c7605',
    18,
    'WBNB',
    'Wrapped BNB',
    'https://www.binance.org'
  ),
  [ChainId.BSC]: new ERC20Token(
    ChainId.BSC,
    '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    18,
    'WBNB',
    'Wrapped BNB',
    'https://www.binance.org'
  ),
  [ChainId.BSC_TESTNET]: new ERC20Token(
    ChainId.BSC_TESTNET,
    '0x888279a0df02189078e3E68fbD93D35183E1Fc69',
    18,
    'WNULS',
    'Wrapped NULS',
    'https://nuls.io'
  ),
}

export const WNATIVE: Record<string, ERC20Token> = {
  [ChainId.ETHEREUM]: WETH9[ChainId.ETHEREUM],
  [ChainId.GOERLI]: WETH9[ChainId.GOERLI],
  [ChainId.BSC]: WBNB[ChainId.BSC],
  [ChainId.BSC_TESTNET]: WBNB[ChainId.BSC_TESTNET],
  [ChainId.ZKSYNC_TESTNET]: WETH9[ChainId.ZKSYNC_TESTNET],
  [ChainId.LINEA_GOERLI]: WETH9[ChainId.LINEA_GOERLI],
  [ChainId.LINEA]: WETH9[ChainId.LINEA],
  [ChainId.BASE]: WETH9[ChainId.BASE]
}

export const NATIVE: Record<
  string,
  {
    name: string
    symbol: string
    decimals: number
  }
> = {
  [ChainId.ETHEREUM]: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  [ChainId.GOERLI]: { name: 'Enuls Chain Native Token', symbol: 'NULS', decimals: 18 },
  [ChainId.BSC]: {
    name: 'Binance Chain Native Token',
    symbol: 'BNB',
    decimals: 18,
  },
  [ChainId.BSC_TESTNET]: {
    name: 'Enuls Chain Native Token',
    symbol: 'NULS',
    decimals: 18,
  },
  [ChainId.ZKSYNC_TESTNET]: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  [ChainId.LINEA_GOERLI]: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  [ChainId.LINEA]: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  [ChainId.BASE]: { name: 'Ether', symbol: 'ETH', decimals: 18 },
}
