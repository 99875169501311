import { isBeta } from '@/utils/utils'

export const NULSKey = isBeta ? '2-1' : '1-1'

export const NSWAPKey = '1-717'

export const MINIMUM_NULS = '0.01'

export const FACTORY_ADDRESS = isBeta
  ? 'tNULSeBaN3ASehPNYytLUYqYyfT9WwmQap3zgF'
  : 'NULSd6Hgp11fYE42kgoAudZCKh7JmxTTeDBvq'

export const PAIR_CODE_HASH = 'af771b00885ba58784a12f2ae00b6cf0ba15056dc9889bacc2583990f8c92b4c'

export const ROUTER_ADDRESS = isBeta
  ? 'tNULSeBaN7HcFAqpJjgAdpKN61iZzG6hLzaGZk'
  : 'NULSd6HgqaiJB6rVBo7mdXwiN8GhRDU7huFeZ'

export const MULTICALL_ADDRESS = isBeta
  ? 'tNULSeBaMwP81fGuNPRRSpKxLto1o1hQPnUTJQ'
  : 'NULSd6Hgrnv1oxcdyhzZmsu7HWgk7vcaR6nMR'

export const WNULS = isBeta
  ? 'tNULSeBaN8aNHMo4yKomvGDbZfL1KAYGwfn8Jk'
  : 'NULSd6HgnjgEdarTNnBRGwhHaXU6MUXyHtLLi'

export const BURN_ADDRESS = isBeta
  ? 'tNULSeBaN5nddf9WkQgRr3RNwARgryndv2Bzs6'
  : 'NULSd6HgsVSzCAJwLYBjvfP3NwbKCvV525GWn'

export const WASSET_FACTORY_ADDRESS = isBeta
  ? 'tNULSeBaN3KSaGWJj7kntJiNs8qVq35fxpHwhq'
  : 'NULSd6HgwmtH2s8Usgwgp97V2SQJggcxLEgHJ'

export const WASSET_CODE_HASH = 'cd95e666e15449a6a199ce9b78415e94703b546e30b0be92c761fc7044c2aa6b'

export const NSWAP_STAKE_REWARD = 5000000 * Math.pow(10, 8)

export const STAKE_CONTRACT = 'NULSd6HgsMXkLFoHfp6dbpSTBsJpc5yd47vyL'

export const STAKE_NULS_CONTRACT = 'NULSd6Hgn6P76C5Q5UtXxkJ5rA57ChyL8MXdh'

export const STAKE_NULS_EARN_CONTRACT = 'NULSd6HgoDkAcqspE6ho7JU3r2F1R1TKPc1H7'

export const VNSWAP_CONTRACT = 'NULSd6HgniW4XSdPw8VH2a75W8U5RvqNCvxrC'
