import { onMounted, onUnmounted } from 'vue'
import { useWalletStore } from '@/stores/wallet'
import { getNULSBalance } from '@/service/api'
import { isBeta } from '@/utils/utils'

export default function useProvider(autoInit = false) {
  const wallet = useWalletStore()

  async function initProvider() {
    if (window.nabox) {
      if (window.nabox?.selectedAddress) {
        wallet.changeAccount(window.nabox.selectedAddress)
        await switchToNULS()
        handleGetNULSBalance()
        addListener()
      } else {
        connect()
      }
    }
  }

  function addListener() {
    window.nabox.on('accountsChanged', handleAccountChange)
  }

  function handleAccountChange(accounts: string[]) {
    wallet.changeAccount(accounts[0] || '')
    handleGetNULSBalance()
  }

  async function connect() {
    await window.nabox.createSession()
    await switchToNULS()
    wallet.changeAccount(window.nabox.selectedAddress)
    handleGetNULSBalance()
  }

  function disconnect() {
    wallet.changeAccount('')
  }

  async function switchToNULS() {
    await window.nabox.switchChain({
      chainId: isBeta ? 2 : 1
    })
  }

  async function handleGetNULSBalance() {
    if (!wallet.isWrongChaing && wallet.address) {
      const balance = await getNULSBalance(wallet.address)
      wallet.balance = balance
    } else {
      wallet.balance = '0'
    }
  }

  onMounted(() => {
    if (autoInit) {
      initProvider()
    }
  })
  onUnmounted(() => {
    window.nabox?.off('accountsChanged', handleAccountChange);
  })

  return {
    initProvider,
    connect,
    disconnect,
    switchToNULS
  }
}