<template>
  <div>
    <AuthButton variant="secondary" @click="showWalletModal = true">
      <template #icon>
        <div
          class="w-6 h-6 rounded-full bg-primary/20 flex items-center justify-center"
        >
          <wallet-icon class="w-3.5 h-3.5 text-primary" />
        </div>
      </template>
      {{ formatAddress(wallet.address) }}
      <chevron-down-icon class="w-4 h-4 text-gray-400" />
    </AuthButton>

    <WalletModal
      :show="showWalletModal"
      @close="showWalletModal = false"
      @disconnect="handleDisconnect"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { WalletIcon, ChevronDownIcon } from "@heroicons/vue/24/outline";
import { useWalletStore } from "@/stores/wallet";
import AuthButton from "./AuthButton.vue";
import WalletModal from "./WalletModal.vue";
import useProvider from "@/hooks/useProvider";

const { disconnect } = useProvider(true);

const wallet = useWalletStore();
const showWalletModal = ref(false);

function handleDisconnect() {
  disconnect();
  showWalletModal.value = false;
}

function formatAddress(address: string): string {
  if (!address) return "";
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
}
</script>
