import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import usePlugins from '@/plugins/index'
import './style.css'
import { initStoreTokens } from './stores/user'

/* import VConsole from 'vconsole'
const isMobile = /Android|webOS|iPhone|iPad|BlackBerry/i.test(
  navigator.userAgent
)
if (isMobile && import.meta.env.MODE === 'beta') {
  new VConsole()
} */

const app = createApp(App)
const pinia = createPinia()

app.use(pinia)
app.use(router)
app.use(usePlugins)

initStoreTokens().then(() => {
  app.mount('#app')
})
