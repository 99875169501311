<template>
  <div class="space-y-3 text-sm">
    <div class="flex justify-between items-center text-gray-400">
      <div class="flex items-center group">
        <span>Price</span>
      </div>
      <div class="flex items-center text-white">
        <span>{{ tradePrice }}</span>
        <button
          class="ml-2 p-1 hover:bg-gray-700/50 rounded-lg transition-all duration-200"
          @click="$emit('toggle-inverted')">
          <arrow-path-icon
            class="h-4 w-4 text-gray-400 hover:text-primary transition-colors" />
        </button>
      </div>
    </div>

    <div class="flex justify-between items-center text-gray-400">
      <div class="flex items-center group">
        <span>Slippage Tolerance</span>
      </div>
      <div class="flex items-center text-white">
        <span>{{ Number(store.slippageTolerance) }}%</span>
        <button
          class="ml-2 p-1 hover:bg-gray-700/50 rounded-lg transition-all duration-200">
          <pencil-icon
            @click="$emit('show-setting')"
            class="h-4 w-4 text-gray-400 hover:text-primary transition-colors" />
        </button>
      </div>
    </div>

    <div class="flex justify-between items-center text-gray-400">
      <div class="flex items-center group">
        <span>{{ isExactIn ? 'Minimum Received' : 'Maximum sold' }}</span>
        <Tooltip
          text="Your transaction will revert if there is a large, unfavorable price movement before it is confirmed." />
      </div>
      <span class="text-white">{{ receiveOrSoldAmount }} {{ isExactIn ? toSymbol : fromSymbol }}</span>
    </div>

    <div class="flex justify-between items-center text-gray-400">
      <div class="flex items-center group">
        <span>Price Impact</span>
        <Tooltip
          text="The difference between the market price and estimated price due to trade size." />
      </div>
      <span :class="priceImpact.color">{{ priceImpact.value }}</span>
    </div>

    <div class="flex justify-between items-center text-gray-400">
      <div class="flex items-center group">
        <span>Liquidity Provider Fee</span>
        <Tooltip text="For each trade a 0.3% fee is paid" />
      </div>
      <span class="text-white">
        {{
          `${realizedLPFee?.toSignificant(4)} ${fromSymbol}`
        }}
      </span>
    </div>

    <div class="flex justify-between items-center text-gray-400">
      <div class="flex items-center group">
        <span>Route</span>
        <Tooltip
          text="Routing through these tokens resulted in the best price for your trade." />
      </div>
      <div class="flex items-center text-white">
        <div
          class="flex items-center"
          v-for="(path, index) in route"
          :key="path.chainId">
          {{ path.symbol }}
          <ChevronRightIcon
            v-if="index !== route.length - 1"
            class="h-3 w-3 mx-1 text-gray-400" />
        </div>
        <!-- <button
          class="ml-2 p-1 hover:bg-gray-700/50 rounded-lg transition-all duration-200"
          @click="$emit('show-route')">
          <magnifying-glass-icon
            class="h-4 w-4 text-gray-400 hover:text-primary transition-colors" />
        </button> -->
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  ArrowPathIcon,
  PencilIcon,
  ChevronRightIcon
} from '@heroicons/vue/24/outline'
import Tooltip from '@/components/Tooltip/index.vue'
import { Currency, CurrencyAmount } from '@/swap-sdk'
import { useUserStore } from '@/stores/user'

 defineProps<{
  isExactIn: boolean
  tradePrice: string
  receiveOrSoldAmount: string
  priceImpact: {value: string, color: string} 
  realizedLPFee?: CurrencyAmount<Currency>
  fromSymbol: string
  toSymbol: string
  route: Currency[]
}>()

/* const props = defineProps<{
  isExactIn: boolean
  price?: Price<Currency, Currency>
  inputAmount?: CurrencyAmount<Currency>
  outputAmount?: CurrencyAmount<Currency>
  slippageAdjustedAmounts: {
    A?: CurrencyAmount<Currency>
    B?: CurrencyAmount<Currency>
  }
  priceImpact?: Percent
  realizedLPFee?: CurrencyAmount<Currency>
  route: Currency[]
}>() */

defineEmits<{
  (e: 'toggle-inverted'): void
  (e: 'show-route'): void
  (e: 'show-setting'): void
}>()

const store = useUserStore()
// const showInverted = ref(false)

// const tradePrice = computed(() => {
//   if (!props.price?.baseCurrency && !props.price?.quoteCurrency) {
//     return '-'
//   }
//   const formattedPrice = showInverted.value
//     ? props.price?.toSignificant(6)
//     : props.price?.invert()?.toSignificant(6)
//   const {
//     price: { baseCurrency, quoteCurrency }
//   } = props

//   return `1 ${showInverted.value ? baseCurrency?.symbol : quoteCurrency?.symbol} ≈ ${formattedPrice} ${showInverted.value ? quoteCurrency?.symbol : baseCurrency?.symbol}`
// })

// const receiveOrSoldAmount = computed(() => {
//   const { isExactIn, slippageAdjustedAmounts, inputAmount, outputAmount } =
//     props
//   return isExactIn
//     ? slippageAdjustedAmounts.B
//       ? `${slippageAdjustedAmounts.B.toSignificant(6)} ${outputAmount?.currency.symbol}`
//       : '-'
//     : slippageAdjustedAmounts.A
//       ? `${slippageAdjustedAmounts.A.toSignificant(6)} ${inputAmount?.currency.symbol}`
//       : '-'
// })

// const priceImpactInfo = computed(() => {
//   const { priceImpact } = props
//   const severity = warningSeverity(priceImpact)
//   const color =
//     severity === 3 || severity === 4
//       ? 'text-[#fb4078]'
//       : severity === 2
//         ? 'text-[#FFB237]'
//         : severity === 1
//           ? ''
//           : 'text-green-400'
//   const value = priceImpact
//     ? priceImpact.lessThan(ONE_BIPS)
//       ? '<0.01%'
//       : `${priceImpact.toFixed(2)}%`
//     : '-'
//   return {
//     color,
//     value
//   }
// })
</script>
