// @ts-ignore
import nuls from 'nuls-sdk-js'
// @ts-ignore
import keccak from 'keccak'
import { isBeta } from '../utils'

import {
  FACTORY_ADDRESS,
  PAIR_CODE_HASH,
  WNULS,
  ROUTER_ADDRESS,
  WASSET_FACTORY_ADDRESS,
  WASSET_CODE_HASH,
  NULSKey
} from '@/constant/contract'

function getExactAddress(tokenAddressOrAssetKey: string) {
  if (!tokenAddressOrAssetKey || tokenAddressOrAssetKey === NULSKey)
    return WNULS
  if (tokenAddressOrAssetKey.includes('-')) {
    return calcWAssetAddress(tokenAddressOrAssetKey)
  }
  return tokenAddressOrAssetKey
}

interface IToken {
  assetKey: string
  address: string
}

export function sortAddress(tokenA: IToken, tokenB: IToken) {
  const tokenAAddress = getExactAddress(tokenA.address || tokenA.assetKey)
  const tokenBAddress = getExactAddress(tokenB.address || tokenB.assetKey)
  let token0
  let token1
  if (nuls.hashCode(tokenAAddress) < nuls.hashCode(tokenBAddress)) {
    token0 = tokenAAddress
    token1 = tokenBAddress
  } else {
    token0 = tokenBAddress
    token1 = tokenAAddress
  }
  return [token0, token1]
}

export function sortsBefore(tokenA: IToken, tokenB: IToken) {
  const tokenAAddress = getExactAddress(tokenA.address || tokenA.assetKey)
  const tokenBAddress = getExactAddress(tokenB.address || tokenB.assetKey)
  return nuls.hashCode(tokenAAddress) < nuls.hashCode(tokenBAddress)
}

export function calcPairAddress(tokenA: IToken, tokenB: IToken) {
  const tokenAAddress = getExactAddress(tokenA.address || tokenA.assetKey)
  const tokenBAddress = getExactAddress(tokenB.address || tokenB.assetKey)
  // TokenA cannot be equal to TokenB
  // console.log(tokenA, tokenB, 234)
  if (tokenAAddress == tokenBAddress) {
    throw 'IDENTICAL_ADDRESSES'
  }
  // Find the correct order of the tokens
  const [token0, token1] = sortAddress(tokenA, tokenB)

  return calcDeployedAddress(
    FACTORY_ADDRESS,
    ['pair', token0, token1],
    PAIR_CODE_HASH
  )

  /* const saltBuf = Buffer.from(
    nuls.programEncodePacked(['pair', token0, token1]),
    'hex'
  )
  const factoryBuf = nuls.getBytesAddress(FACTORY_ADDRESS)
  const pairCodeHashBuf = Buffer.from(PAIR_CODE_HASH, 'hex')
  const createData = nuls.programCreateDataEncodePacked(
    factoryBuf,
    saltBuf,
    pairCodeHashBuf
  )
  const hash160 = nuls.sha256ripemd160(
    keccak('keccak256').update(Buffer.from(createData, 'hex')).digest()
  )
  const chainIdBuffer = Buffer.concat([
    Buffer.from([0xff & (chainId >> 0)]),
    Buffer.from([0xff & (chainId >> 8)])
  ])
  const addrBuffer = Buffer.concat([chainIdBuffer, Buffer.from([2]), hash160])
  return nuls.getStringAddressByBytes(addrBuffer) */
}

// cal wassest address
export function calcWAssetAddress(assetKey: string) {
  const [assetChainId, assetId] = assetKey.split('-')
  const salt = ['wasset', 'w' + assetChainId, 'w' + assetId]
  return calcDeployedAddress(WASSET_FACTORY_ADDRESS, salt, WASSET_CODE_HASH)
}

function calcDeployedAddress(
  factoryAddress: string,
  salt: string[],
  codeHash: string
) {
  const chainId = isBeta ? 2 : 1
  const factoryBuf = nuls.getBytesAddress(factoryAddress)
  const saltBuf = Buffer.from(nuls.programEncodePacked(salt), 'hex')
  const codeHashBuf = Buffer.from(codeHash, 'hex')
  const createData = nuls.programCreateDataEncodePacked(
    factoryBuf,
    saltBuf,
    codeHashBuf
  )
  const hash160 = nuls.sha256ripemd160(
    keccak('keccak256').update(Buffer.from(createData, 'hex')).digest()
  )
  const chainIdBuffer = Buffer.concat([
    Buffer.from([0xff & (chainId >> 0)]),
    Buffer.from([0xff & (chainId >> 8)])
  ])
  const addrBuffer = Buffer.concat([chainIdBuffer, Buffer.from([2]), hash160])
  return nuls.getStringAddressByBytes(addrBuffer)
}

export function getDeadLine() {
  const now = new Date().getTime() + ''
  return Number(now.slice(0, now.length - 3)) + 300
}

export async function getTokenAllowance(
  tokenContract: string,
  owner: string,
  contractAddress: string
) {
  const data = {
    contractAddress: tokenContract,
    methodName: 'allowance',
    methodDesc: '(Address owner, Address spender) return BigInteger',
    args: [owner, contractAddress]
  }
  const res = await window.nabox.invokeView(data)
  return res?.result || '0'
}

export async function approveToken(
  tokenContract: string,
  owner: string,
  amount: string,
  contractAddress: string
) {
  const data = {
    from: owner,
    value: 0,
    contractAddress: tokenContract,
    methodName: 'approve',
    methodDesc: '',
    args: [contractAddress, amount]
  }
  return await window.nabox.contractCall(data)
}

export * from './liquidity'
export * from './swap'
export * from './multiCall'
export * from './stake'
