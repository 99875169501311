<template>
  <BaseModal :show="show" @close="$emit('close')">
    <div class="modal-wrap">
      <div class="flex justify-between items-center mb-6">
        <h3 class="text-xl font-bold text-white">Recent Transactions</h3>
        <button class="text-gray-400 hover:text-white" @click="$emit('close')">
          <x-mark-icon class="h-5 w-5" />
        </button>
      </div>

      <div v-if="store.myTxs.length === 0" class="text-center py-12">
        <p class="text-gray-400">No recent transactions</p>
      </div>

      <div v-else class="space-y-4 max-h-80 overflow-auto">
        <div
          v-for="tx in store.myTxs"
          :key="tx.hash"
          class="flex items-center justify-between p-3 rounded-xl bg-gray-700/30 md:p-4">
          <div class="flex items-center">
            <check-circle-icon
              v-if="tx.status === 1"
              class="h-5 w-5 text-green-400 mr-2" />
            <svg
              v-else
              class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24">
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            <span class="text-white">{{ tx.summary }}</span>
          </div>
          <a :href="getOpenUrl(tx.hash, 'hash')" target="_blank">
            <external-link-icon
              class="h-5 w-5 text-gray-400 hover:text-white cursor-pointer" />
          </a>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script setup lang="ts">
import {
  XMarkIcon,
  CheckCircleIcon,
  ArrowTopRightOnSquareIcon as ExternalLinkIcon
} from '@heroicons/vue/24/outline'
import BaseModal from '@/components/ui/BaseModal.vue'
import { getOpenUrl } from '@/utils/utils'
import { useUserStore } from '@/stores/user'

defineProps<{
  show: boolean
}>()

defineEmits<{
  (e: 'close'): void
}>()

const store = useUserStore()
</script>
