import { createRouter, createWebHistory } from 'vue-router'
import SwapView from '../views/swap/index.vue'
import PoolsView from '../views/pools/index.vue'
import StakeView from '../views/stake/index.vue'
import AiNulsView from '../views/ainuls/index.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      redirect: '/swap'
    },
    {
      path: '/swap/:assetKeyA?/:assetKeyB?',
      name: 'swap',
      component: SwapView
    },
    {
      path: '/pools/:assetKeyA?/:assetKeyB?',
      name: 'pools',
      component: PoolsView
    },
    {
      path: '/stake',
      name: 'stake',
      component: StakeView
    },
    {
      path: '/ainuls',
      name: 'ainuls',
      component: AiNulsView
    }
  ]
})

export default router