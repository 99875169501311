import { divisionDecimals, isBeta } from '@/utils/utils'
import http from '@/service'
import config from '@/config'

const apiUrl = config.baseUrl
const chainId = config.chainId

import type { RpcRes, BroadCast, Tx } from './types'

function createRPCParams(method: string, data: any): any {
  return {
    jsonrpc: '2.0',
    id: Math.floor(Math.random() * 1000),
    method,
    params: data
  }
}

interface IAsset {
  assetChainId: number
  assetId: number
  contractAddress: string
  balance: string
  nonce: string
}

export async function getNULSBalance(address: string) {
  const params = createRPCParams('getAccountBalance', [
    chainId,
    chainId,
    1,
    address
  ])
  const result = await http.rPost<RpcRes<IAsset>>({
    url: apiUrl,
    data: params
  })
  if (result.result) {
    return divisionDecimals(result.result.balance, 8)
  }
  return '0'
}

interface IBatchQuery {
  chainId: number
  assetId: number
  contractAddress: string
}
export async function getNAssetsBalance(
  address: string,
  assetsInfo: IBatchQuery[]
) {
  const params = createRPCParams('getBalanceList', [
    chainId,
    address,
    assetsInfo
  ])
  const result = await http.rPost<RpcRes<IAsset[]>>({
    url: apiUrl,
    data: params
  })
  return result.result || []
}

export async function getTxInfo(hash: string) {
  const params = createRPCParams('getTx', [chainId, hash])
  return await http.rPost<RpcRes<BroadCast>>({
    url: apiUrl,
    data: params
  })
}

export async function broadcastTx(txHex: string) {
  const params = createRPCParams('broadcastTx', [chainId, txHex])
  return await http.rPost<RpcRes<BroadCast>>({
    url: apiUrl,
    data: params
  })
}

export async function getTxs(txs: string[]) {
  const params = txs.map(v => createRPCParams('getTx', [chainId, v]))
  const apiResult = await http.rPost<RpcRes<Tx> | RpcRes<Tx>[]>({
    url: apiUrl,
    data: params
  })
  if (Array.isArray(apiResult)) {
    if (apiResult.length) {
      return (
        apiResult.map(v => ({
          hash: v.result?.hash,
          status: v.result?.status
        })) || []
      )
    }
  } else {
    return [{ hash: apiResult.result?.hash, status: apiResult.result?.status }]
  }

  return []
}

export async function getLatestHeight() {
  const params = createRPCParams('getLatestHeight', [chainId])
  return await http.rPost<RpcRes<string>>({
    url: apiUrl,
    data: params
  })
}

interface IInvokeView {
  contractAddress: string
  methodName: string
  methodDesc: string
  args: any[]
}
export async function invokeView(data: IInvokeView) {
  const { contractAddress, methodName, methodDesc, args } = data
  const params = createRPCParams('invokeView', [
    chainId,
    contractAddress,
    methodName,
    methodDesc,
    args
  ])
  const result = await http.rPost<RpcRes<{ result: string }>>({
    url: apiUrl,
    data: params
  })
  return result.result || { result: '' }
}

export async function getNULSApr() {
  const params = createRPCParams('getAnnulizedRewardStatistical', [chainId, 3])
  const result = await http.rPost<any>({
    url: 'https://public1.nuls.io/',
    data: params
  })
  const res = result.result
  if (res?.length) {
    return res[res.length - 1].value;
  }
  return ''
}
