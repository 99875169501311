import { ref, computed } from 'vue'
import { ILP } from './useUserLPs'
import { usePair } from './usePairs'
import { useWalletStore } from '@/stores/wallet'
import { useUserStore } from '@/stores/user'
import { Division, Times, fixNumber, timesDecimals } from '@/utils/utils'
import useToast from '@/hooks/useToast'
import {
  removeTokenLiquidity,
  removeTokenLiquidityWithNULS,
  removeLiquidityWAssetWAsset,
  removeLiquidityWAssetNULS,
  removeLiquidityWAssetToken
} from '@/utils/nuls'
import { NULSKey } from '@/constant/contract'
import { LP_DECIMALS } from '@/constant/exchange'

export default function useRemoveLiquidity(lp: ILP) {
  const walletStore = useWalletStore()
  const userStore = useUserStore()
  const { toastSuccess, toastError } = useToast()
  const tokenA = computed(() => lp.tokenA)
  const tokenB = computed(() => lp.tokenB)
  const pairInfo = usePair(tokenA, tokenB)
  const liq = ref('')

  const receiveAmount = computed(() => {
    if (!pairInfo.value || !liq.value) {
      return {
        tokenA: '',
        tokenb: ''
      }
    }

    const reserve0 = pairInfo.value.reserve0.toSignificant(lp.tokenA.decimals)
    const reserve1 = pairInfo.value.reserve1.toSignificant(lp.tokenB.decimals)
    const totalSupply = lp.lpToken.totalSupply
    const percent = Division(timesDecimals(liq.value, LP_DECIMALS), totalSupply)
    return {
      tokenA: fixNumber(Times(reserve0, percent).toFixed(), lp.tokenA.decimals),
      tokenB: fixNumber(Times(reserve1, percent).toFixed(), lp.tokenB.decimals)
    }
  })

  const removeLiquidity = async () => {
    const {
      assetKey: assetKeyA,
      address: addressA,
      symbol: symbolA
    } = tokenA.value
    const {
      assetKey: assetKeyB,
      address: addressB,
      symbol: symbolB
    } = tokenB.value
    const tokenAIsNULS = assetKeyA === NULSKey
    const tokenBIsNULS = assetKeyB === NULSKey
    const tokenAIsWasset = !addressA && !tokenAIsNULS
    const tokenBIsWasset = !addressB && !tokenBIsNULS
    const liquidity = timesDecimals(liq.value, LP_DECIMALS)
    const owner = walletStore.address
    let hash
    try {
      if (tokenAIsWasset || tokenBIsWasset) {
        if (tokenAIsWasset && tokenBIsWasset) {
          hash = await removeLiquidityWAssetWAsset(
            owner,
            assetKeyA,
            assetKeyB,
            liquidity
          )
        } else if (tokenAIsNULS || tokenBIsNULS) {
          const wAssetKey = tokenAIsWasset ? assetKeyA : assetKeyB
          hash = await removeLiquidityWAssetNULS(owner, wAssetKey, liquidity)
        } else {
          const wAssetKey = tokenAIsWasset ? assetKeyA : assetKeyB
          const tokenAddress = tokenAIsWasset ? addressB : addressA
          hash = await removeLiquidityWAssetToken(
            owner,
            wAssetKey,
            tokenAddress,
            liquidity
          )
        }
      } else if (tokenAIsNULS || tokenBIsNULS) {
        hash = await removeTokenLiquidityWithNULS(
          owner,
          tokenAIsNULS ? addressB : addressA,
          liquidity
        )
      } else {
        hash = await removeTokenLiquidity(owner, addressA, addressB, liquidity)
      }
      // console.log(hash, '---hash---')
      if (hash) {
        toastSuccess('Success')
        const tx = {
          summary: `Remove ${receiveAmount.value.tokenA} ${symbolA} and ${receiveAmount.value.tokenB} ${symbolB}`,
          hash,
          time: new Date().getTime() + '',
          status: 0 as const
        }
        userStore.addTx(tx)
      }
    } catch (e) {
      toastError(e)
    }
  }

  return {
    liq,
    receiveAmount,
    removeLiquidity
  }
}
