import { computed, ComputedRef, Ref, ref, watch } from 'vue'
import {
  Trade,
  Currency,
  TradeType,
  isTradeBetter,
  ERC20Token,
  Pair
} from '@/swap-sdk'
import { BETTER_TRADE_LESS_HOPS_THRESHOLD } from '@/constant/exchange'
import { flatMap } from 'lodash'
import { handleGetPairInfo } from '@/views/pools/hooks/usePairs'
import { IToken } from '@/stores/user'
import tryParseAmount from '@/utils/tryParseAmount'
import { Times, Division } from '@/utils/utils'
import { useUserStore } from '@/stores/user'

function getToken(token?: IToken) {
  if (!token) return undefined
  return new ERC20Token(
    token.assetKey,
    token.address,
    token.decimals,
    token.symbol
  )
}

export function useAllCommonPairs(tokenA?: Ref<IToken>, tokenB?: Ref<IToken>) {
  const userStore = useUserStore()

  /* const bases = BaseTokens

  const basePairs: [IToken, IToken][] = flatMap(
    bases,
    (base): [IToken, IToken][] => bases.map(otherBase => [base, otherBase])
  ) */

  // const bases = userStore.tokens
  const bases = userStore.baseTokens
  const basePairs: [IToken, IToken][] = flatMap(
    bases,
    (base): [IToken, IToken][] => bases.map(otherBase => [base, otherBase])
  )

  const allPairCombinations = computed(() =>
    tokenA?.value && tokenB?.value
      ? [
          // the direct pair
          [tokenA.value && tokenB.value],
          // token A against all bases
          ...bases.map(base => [tokenA.value!, base]),
          // token B against all bases
          ...bases.map(base => [tokenB.value!, base]),
          // each base against all bases
          ...basePairs
        ]
          .filter((tokens): tokens is [IToken, IToken] =>
            Boolean(tokens[0] && tokens[1])
          )
          .filter(([t0, t1]) => t0.assetKey !== t1.assetKey)
      : []
  )

  const allPairs = ref<Pair[]>([])

  watch(
    [
      () => allPairCombinations.value,
      () => userStore.blockHeight,
      () => userStore.refreshSwap
    ],
    ([pairs, height, refreshSwap]) => {
      if ((pairs.length && height) || refreshSwap) {
        handleGetPairInfo(pairs).then(res => {
          allPairs.value = res.filter(v => v !== null)
        })
      }
    }
    // { deep: true }
  )

  // only pass along valid pairs, non-duplicated pairs
  const AllCommonPairs = computed(() => {
    return Object.values(
      allPairs.value
        // filter out duplicated pairs
        .reduce<{ [pairAddress: string]: Pair }>((memo, curr) => {
          const key = curr.liquidityToken.address
          memo[key] = memo[key] ?? curr
          return memo
        }, {})
    )
  })

  return AllCommonPairs
}

const MAX_HOPS = 3

/**
 * Returns the best trade for the exact amount of tokens in to the given token out
 */
export function useTradeExactIn(
  allowedPairs: ComputedRef<Pair[]>,
  tokenA: Ref<IToken>,
  tokenB: Ref<IToken>,
  amountA: Ref<string>,
  platformFee: Ref<string>
) {
  // const allowedPairs = useAllCommonPairs(tokenA, tokenB)
  const currencyIn = computed(() => {
    return getToken(tokenA?.value)
  })
  const currencyOut = computed(() => {
    return getToken(tokenB?.value)
  })

  const bestTradeExactIn = computed(() => {
    const inAmount = Times(
      amountA.value,
      Division(100 - Division(platformFee.value, 100).toNumber(), 100).toFixed()
    ).toFixed(currencyIn.value?.decimals || 8)
    const currencyAmountIn = tryParseAmount(inAmount, currencyIn.value)
    // debugger
    if (currencyAmountIn && currencyOut && allowedPairs.value.length > 0) {
      // search through trades with varying hops, find best trade out of them
      let bestTradeSoFar: Trade<Currency, Currency, TradeType> | null = null
      for (let i = 1; i <= MAX_HOPS; i++) {
        const currentTrade: Trade<Currency, Currency, TradeType> | null =
          Trade.bestTradeExactIn(
            allowedPairs.value,
            currencyAmountIn,
            currencyOut.value!,
            {
              maxHops: i,
              maxNumResults: 1
            }
          )[0] ?? null
        // if current trade is best yet, save it。   bestTradeSoFar.executionPrice * 100.5% < currentTrade.executionPrice
        if (
          isTradeBetter(
            bestTradeSoFar,
            currentTrade,
            BETTER_TRADE_LESS_HOPS_THRESHOLD
          )
        ) {
          bestTradeSoFar = currentTrade
        }
      }
      return bestTradeSoFar
    }

    return null
  })

  return bestTradeExactIn
}

/**
 * Returns the best trade for the token in to the exact amount of token out
 */
export function useTradeExactOut(
  allowedPairs: ComputedRef<Pair[]>,
  tokenA: Ref<IToken>,
  tokenB: Ref<IToken>,
  amountB: Ref<string>
) {
  // const allowedPairs = useAllCommonPairs(tokenA, tokenB)

  const currencyIn = computed(() => {
    return getToken(tokenA?.value)
  })
  const currencyOut = computed(() => {
    return getToken(tokenB?.value)
  })

  const bestTradeExactOut = computed(() => {
    const currencyAmountOut = tryParseAmount(amountB.value, currencyOut.value)

    if (
      currencyIn.value &&
      currencyAmountOut &&
      allowedPairs.value.length > 0
    ) {
      // search through trades with varying hops, find best trade out of them
      let bestTradeSoFar: Trade<Currency, Currency, TradeType> | null = null
      for (let i = 1; i <= MAX_HOPS; i++) {
        const currentTrade =
          Trade.bestTradeExactOut(
            allowedPairs.value,
            currencyIn.value,
            currencyAmountOut,
            {
              maxHops: i,
              maxNumResults: 1
            }
          )[0] ?? null
        if (
          isTradeBetter(
            bestTradeSoFar,
            currentTrade,
            BETTER_TRADE_LESS_HOPS_THRESHOLD
          )
        ) {
          bestTradeSoFar = currentTrade
        }
      }
      return bestTradeSoFar
    }
    return null
  })
  return bestTradeExactOut
}
