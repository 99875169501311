import { BigNumber } from 'bignumber.js'
import config from '@/config'

const MODE = import.meta.env.MODE

export const isBeta = MODE === 'beta'

type Big = BigNumber | string | number


export const Power = (arg: Big) => {
  const newPower = new BigNumber(10)
  return newPower.pow(arg)
}

export const Plus = (nu: Big, arg: Big) => {
  const newPlus = new BigNumber(nu)
  return newPlus.plus(arg)
}

export const Minus = (nu: Big, arg: Big) => {
  const newMinus = new BigNumber(nu)
  return newMinus.minus(arg)
}

export const Times = (nu: Big, arg: Big) => {
  const newTimes = new BigNumber(nu)
  return newTimes.times(arg)
}

export const Division = (nu: Big, arg: Big) => {
  const newDiv = new BigNumber(nu)
  return newDiv.div(arg)
}

export const timesDecimals = (nu: Big, decimals = 8) => {
  return new BigNumber(Times(nu, Power(decimals.toString()).toString()))
    .toFormat()
    .replace(/[,]/g, '')
}

export const divisionDecimals = (nu: Big, decimals = 8) => {
  if (!nu) return ''
  return new BigNumber(Division(nu, Power(decimals.toString()).toString()))
    .toFormat()
    .replace(/[,]/g, '')
}

export function divisionAndFix(nu: Big, decimals = 8, fix = 6) {
  const newFix = fix ? fix : Number(decimals)
  const str = new BigNumber(Division(nu, Power(decimals))).toFixed(newFix)
  const pointIndex = str.indexOf('.')
  let lastStr = str.substr(str.length - 1)
  let lastIndex = str.length
  while (lastStr === '0' && lastIndex >= pointIndex) {
    lastStr = str.substr(lastIndex - 1, 1)
    if (lastStr === '0') {
      lastIndex = lastIndex - 1
    }
  }
  lastIndex = str.substr(lastIndex - 1, 1) === '.' ? lastIndex - 1 : lastIndex
  return str.substring(0, lastIndex)
}

export function fixNumber(str: string, fix = 8) {
  str = '' + str
  const int = str.split('.')[0]
  let float = str.split('.')[1]
  if (!float || !Number(float)) return int
  float = float.slice(0, fix).replace(/(0+)$/g, '')
  return Number(float) ? int + '.' + float : int
}

export const superLong = (str: string, len = 8) => {
  if (str && str.length > 10) {
    return (
      str.substr(0, len) + '....' + str.substr(str.length - len, str.length)
    )
  } else {
    return str
  }
}

export function getOpenUrl(query: string, type: string) {
  let url = config.scanUrl
  if (type === 'address') {
    url += '/address/info?address=' + query
  } else if (type === 'hash') {
    url += '/transaction/info?hash=' + query
  }
  return url
}


export function createRegex(decimal: number) {
  if (decimal === 0) {
    return /^(?:0|[1-9]\d*)$/;
  }
  return new RegExp(`^(?:(?:0|[1-9]\\d*)(?:\\.\\d{0,${decimal}})?|\\.\\d{1,${decimal}})$`);
}