import { computed, Ref, ref, watch } from 'vue'
import {
  CurrencyAmount,
  Pair,
  Currency,
  ERC20Token,
  JSBI,
  Price,
  Token,
  Percent
} from '@/swap-sdk'
import {
  addTokenLiquidityWithNULS,
  addTokenLiquidity,
  addLiquidityWAssetAndWAsset,
  addLiquidityWAssetNULS,
  addLiquidityWAssetToken
} from '@/utils/nuls'
import { getPairTotalSupply } from '@/utils/nuls/multiCall'
import tryParseAmount from '@/utils/tryParseAmount'
import { usePair } from './usePairs'
import { useWalletStore } from '@/stores/wallet'
import { useUserStore } from '@/stores/user'
import { IToken } from '@/stores/user'
import { timesDecimals } from '@/utils/utils'
import useToast from '@/hooks/useToast'
import { NULSKey } from '@/constant/contract'
import { BIG_INT_ZERO } from '@/constant/exchange'

export default function useAddLiquidity(
  tokenA: Ref<IToken>,
  tokenB: Ref<IToken>,
  amountA: Ref<string>,
  amountB: Ref<string>
) {
  const walletStore = useWalletStore()
  const userStore = useUserStore()
  const { toastSuccess, toastError } = useToast()
  const pairInfo = usePair(tokenA, tokenB)

  const isExactA = ref(true)
  const currencyA = computed(() => {
    return new ERC20Token(
      tokenA.value.assetKey,
      tokenA.value.address,
      tokenA.value.decimals,
      tokenA.value.symbol
    )
  })
  const currencyB = computed(() => {
    return new ERC20Token(
      tokenB.value.assetKey,
      tokenB.value.address,
      tokenB.value.decimals,
      tokenB.value.symbol
    )
  })

  const currencyAAmount = computed(() => {
    return tryParseAmount(amountA.value, currencyA.value)
  })

  const currencyBAmount = computed(() => {
    return tryParseAmount(amountB.value, currencyB.value)
  })

  const noLiquidity = computed(() => {
    if (!pairInfo.value) return true
    return (
      JSBI.equal(pairInfo.value.reserve0.quotient, BIG_INT_ZERO) &&
      JSBI.equal(pairInfo.value.reserve1.quotient, BIG_INT_ZERO)
    )
  })

  const price = computed(() => {
    // console.log(pairInfo.value?.reserve0.toFixed(), 'reserve0reserve0')
    // console.log(pairInfo.value?.reserve1.toFixed(), 'reserve1reserve1reserve1')
    if (noLiquidity.value) {
      if (currencyAAmount.value && currencyBAmount.value) {
        return new Price(
          currencyAAmount.value.currency,
          currencyBAmount.value.currency,
          currencyAAmount.value.quotient,
          currencyBAmount.value.quotient
        )
      }
      return undefined
    }
    const wrappedCurrencyA = currencyA.value?.wrapped
    // console.log(wrappedCurrencyA, 234, pairInfo.value)
    return pairInfo.value && wrappedCurrencyA
      ? pairInfo.value.priceOf(wrappedCurrencyA)
      : undefined
  })

  const totalSupply = ref<CurrencyAmount<Token> | undefined>()
  watch(pairInfo, val => {
    // totalSupply.value = undefined
    if (val) {
      const liquidityToken = val.liquidityToken
      getPairTotalSupply(liquidityToken.address).then(res => {
        totalSupply.value = CurrencyAmount.fromRawAmount(liquidityToken, res)
      })
      if (isExactA.value) {
        formatAmountB(amountA.value)
      } else {
        formatAmountA(amountB.value)
      }
    }
  })

  const liquidityMinted = computed(() => {
    const [tokenAmountA, tokenAmountB] = [
      currencyAAmount.value?.wrapped,
      currencyBAmount.value?.wrapped
    ]
    if (pairInfo.value && totalSupply.value && tokenAmountA && tokenAmountB) {
      try {
        return pairInfo.value.getLiquidityMinted(
          totalSupply.value,
          tokenAmountA,
          tokenAmountB
        )
      } catch (error) {
        console.error(error)
        return undefined
      }
    }
    return undefined
  })

  const poolTokenPercentage = computed(() => {
    if (liquidityMinted.value && totalSupply.value) {
      return new Percent(
        liquidityMinted.value.quotient,
        totalSupply.value.add(liquidityMinted.value).quotient
      )
    }
    return undefined
  })

  const formatAmountA = (amountBVal: string) => {
    amountB.value = amountBVal
    if (noLiquidity.value) return

    const independentAmount = currencyBAmount.value
    if (!independentAmount) {
      amountA.value = ''
      return
    }

    const wrappedIndependentAmount = independentAmount.wrapped
    const dependentTokenAmount = pairInfo
      .value!.priceOf(currencyB.value.wrapped)
      .quote(wrappedIndependentAmount)
    amountA.value = dependentTokenAmount.toExact()
  }

  const formatAmountB = (amountAVal: string) => {
    amountA.value = amountAVal
    if (noLiquidity.value) return

    const independentAmount = currencyAAmount.value
    if (!independentAmount) {
      amountB.value = ''
      return
    }

    const wrappedIndependentAmount = independentAmount.wrapped
    const dependentTokenAmount = pairInfo
      .value!.priceOf(currencyA.value.wrapped)
      .quote(wrappedIndependentAmount)
    amountB.value = dependentTokenAmount.toExact()
  }

  const addLiquidity = async () => {
    const {
      assetKey: assetKeyA,
      address: addressA,
      decimals: decimalsA,
      symbol: symbolA
    } = tokenA.value
    const {
      assetKey: assetKeyB,
      address: addressB,
      decimals: decimalsB,
      symbol: symbolB
    } = tokenB.value
    const tokenAIsNULS = assetKeyA === NULSKey
    const tokenBIsNULS = assetKeyB === NULSKey
    const tokenAIsWasset = !addressA && !tokenAIsNULS
    const tokenBIsWasset = !addressB && !tokenBIsNULS
    const tokenAAmount = timesDecimals(amountA.value, decimalsA)
    const tokenBAmount = timesDecimals(amountB.value, decimalsB)
    const owner = walletStore.address
    let hash
    const tx = {
      summary: `Add ${amountA.value} ${symbolA} and ${amountB.value} ${symbolB}`,
      hash: '',
      time: new Date().getTime() + '',
      status: 0 as const
    }
    try {
      if (tokenAIsWasset || tokenBIsWasset) {
        if (tokenAIsWasset && tokenBIsWasset) {
          hash = await addLiquidityWAssetAndWAsset(
            owner,
            assetKeyA,
            assetKeyB,
            amountA.value,
            amountB.value
          )
        } else if (tokenAIsNULS || tokenBIsNULS) {
          const wAssetKey = tokenAIsWasset ? assetKeyA : assetKeyB
          const wAssetAmount = tokenAIsWasset ? amountA.value : amountB.value
          const NULSValue = tokenAIsWasset ? amountB.value : amountA.value
          hash = await addLiquidityWAssetNULS(
            owner,
            wAssetKey,
            wAssetAmount,
            NULSValue
          )
        } else {
          const wAssetKey = tokenAIsWasset ? assetKeyA : assetKeyB
          const tokenAddress = tokenAIsWasset ? addressB : addressA
          const wAssetAmount = tokenAIsWasset ? amountA.value : amountB.value
          const tokenAmount = tokenAIsWasset ? tokenBAmount : tokenAAmount
          hash = await addLiquidityWAssetToken(
            owner,
            wAssetKey,
            tokenAddress,
            wAssetAmount,
            tokenAmount
          )
        }
      } else if (tokenAIsNULS || tokenBIsNULS) {
        const tokenAddress = tokenAIsNULS ? addressB : addressA
        const tokenAmount = tokenAIsNULS ? tokenBAmount : tokenAAmount
        const NULSAmount = tokenAIsNULS ? amountA.value : amountB.value
        hash = await addTokenLiquidityWithNULS(
          owner,
          tokenAddress,
          tokenAmount,
          NULSAmount
        )
      } else {
        hash = await addTokenLiquidity(
          owner,
          addressA,
          addressB,
          tokenAAmount,
          tokenBAmount
        )
      }
      console.log(hash, '---hash---')
      if (hash) {
        toastSuccess('Success')
        tx.hash = hash
        userStore.addTx(tx)
      }
    } catch (e) {
      toastError(e)
    }
  }

  return {
    isExactA,
    noLiquidity,
    price,
    poolTokenPercentage,
    formatAmountA,
    formatAmountB,
    addLiquidity
  }
}
