import { ref, reactive, computed, watch } from 'vue'
import { useWalletStore } from '@/stores/wallet'
import { useUserStore } from '@/stores/user'
import { getStakedLocked, getUserStakeData, getStakeNSWAPEarnNULSData } from '@/utils/nuls'
import { divisionAndFix, Division, fixNumber } from '@/utils/utils'
import { NULSKey, NSWAPKey, NSWAP_STAKE_REWARD } from '@/constant/contract'

export enum STAKE_TYPE {
  STAKE_NSWAP = 0,
  STAKE_NULS_EARN_NULS = 1,
  STAKE_NULS_EARN_NSWAP = 2,
  STAKE_NSWAP_EARN_NULS = 3
}

export enum STAKE_HANDLER {
  STAKE = 0,
  COMPOUND = 1,
  UNSTAKE_ALL = 2,
  GET_REWARD = 3,
  UNSTAKE = 4,
  HARVEST = 5
}

export default function useStateData() {
  const walletStore = useWalletStore()
  const userStore = useUserStore()

  const stake_nswap_earn_nswap = reactive({
    locked: '0',
    apr: '',
    nswapStaked: '',
    nswapEarned: '',
    lockTime: ''
  })

  const stake_nswap_earn_nuls = reactive({
    locked: '0',
    farmedVNSWAP: '',
    claimableVNSWAP: '',
    nswapStaked: '',
    nulsEarned: ''
  })

  const fetchData = () => {
    getStakedLocked().then(res => {
      const [locked1, locked2] = res
      stake_nswap_earn_nswap.locked = divisionAndFix(locked1, 8, 2)
      stake_nswap_earn_nswap.apr = fixNumber(
        Division(NSWAP_STAKE_REWARD, Division(locked1, 100)).toFixed(),
        2
      )
      stake_nswap_earn_nuls.locked = divisionAndFix(
        locked2,
        NSWAP.value?.decimals,
        2
      )
    })
    if (walletStore.address) {
      getUserStakeData(walletStore.address).then(res => {
        const [staked, reward, _lockTime] = res
        stake_nswap_earn_nswap.nswapStaked = divisionAndFix(staked, 8, 2)
        stake_nswap_earn_nswap.nswapEarned = divisionAndFix(reward, 8, 2)
        const time = _lockTime
          ? new Date(_lockTime * 1000).toLocaleString()
          : '-'
          stake_nswap_earn_nswap.lockTime = time
      })

      getStakeNSWAPEarnNULSData(walletStore.address).then(res => {
        // console.log(res, 'getStakeNSWAPEarnNULSData')
        const [farmedVNSWAP, claimableVNSWAP, nswapStaked, nulsEarned] = res
        stake_nswap_earn_nuls.farmedVNSWAP = farmedVNSWAP
          ? divisionAndFix(farmedVNSWAP, NSWAP.value?.decimals, 2)
          : ''
        stake_nswap_earn_nuls.claimableVNSWAP = claimableVNSWAP
          ? divisionAndFix(claimableVNSWAP, NSWAP.value?.decimals, 2)
          : ''
        stake_nswap_earn_nuls.nswapStaked = nswapStaked
          ? divisionAndFix(nswapStaked, NSWAP.value?.decimals, 2)
          : ''
        stake_nswap_earn_nuls.nulsEarned = nulsEarned
          ? divisionAndFix(nulsEarned, NULS.value?.decimals, 2)
          : ''
      })
    }
  }
  watch(
    () => userStore.blockHeight,
    () => {
      fetchData()
    },
    { immediate: true }
  )

  watch(
    () => walletStore.address,
    () => {
      fetchData()
    }
  )

  const NULS = computed(() => {
    return userStore.tokenWithBalances.find(v => v.assetKey === NULSKey)
  })

  const NSWAP = computed(() => {
    return userStore.tokenWithBalances.find(v => v.assetKey === NSWAPKey)
  })

  return {
    stake_nswap_earn_nswap,
    stake_nswap_earn_nuls,
    NSWAP
  }
}
