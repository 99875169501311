```vue
<template>
  <BaseModal :show="show" @close="$emit('close')">
    <div class="modal-wrap">
      <div class="flex justify-between items-center mb-6">
        <h3 class="text-xl font-bold text-white">Manage Tokens</h3>
        <button class="text-gray-400 hover:text-white" @click="$emit('close')">
          <x-mark-icon class="h-5 w-5" />
        </button>
      </div>

      <div class="space-y-4">
        <div>
          <h4 class="text-sm font-medium text-gray-400 mb-2">Custom Tokens</h4>
          <div class="relative">
            <input
              v-model="tokenAddress"
              type="text"
              placeholder="Enter token name or address"
              class="w-full px-4 py-3 rounded-xl bg-gray-700/50 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primary" />
          </div>
          <template v-if="loading === LoadingStatus.loading">
            <div class="flex items-center justify-center text-gray-400 my-4">
              <svg
                class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24">
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            </div>
          </template>
          <template v-else-if="loading === LoadingStatus.loaded">
            <template v-if="searchResult.length">
              <div
                v-for="token in searchResult"
                :key="token.assetKey"
                class="flex items-center justify-between p-3 rounded-xl bg-gray-700/30 mt-2">
                <div class="flex items-center">
                  <img
                    :src="token.icon"
                    class="w-8 h-8 rounded-full"
                    :alt="token.symbol" />
                  <div class="ml-3">
                    <div class="text-white font-medium">{{ token.symbol }}</div>
                    <div class="text-sm text-gray-400 block md:hidden">
                      {{
                        token.address
                          ? superLong(token.address, 5)
                          : NULSKey !== token.assetKey
                            ? token.assetKey
                            : ''
                      }}
                    </div>
                    <div class="text-sm text-gray-400 hidden md:block">
                      {{
                        token.address
                          ? token.address
                          : NULSKey !== token.assetKey
                            ? token.assetKey
                            : ''
                      }}
                    </div>
                  </div>
                </div>
                <button
                  v-if="!checkExist(token)"
                  class="px-4 py-1.5 rounded-lg bg-primary text-white text-sm font-medium hover:bg-primary-dark transition-colors"
                  @click="importToken(token)">
                  Import
                </button>
              </div>
            </template>
            <template v-else>
              <div
                class="flex items-center justify-center text-gray-400 my-4 text-sm">
                No Data
              </div>
            </template>
          </template>
          <template v-else></template>
        </div>

        <div>
          <h4 class="text-sm font-medium text-gray-400 mb-2">
            Imported Tokens
          </h4>
          <div
            v-if="store.focusTokens.length === 0"
            class="text-center py-8 text-gray-400">
            No imported tokens
          </div>
          <div v-else class="space-y-2">
            <div
              v-for="token in store.focusTokens"
              :key="token.assetKey"
              class="flex items-center justify-between p-3 rounded-xl bg-gray-700/30">
              <div class="flex items-center">
                <img
                  :src="token.icon"
                  class="w-8 h-8 rounded-full"
                  :alt="token.symbol" />
                <div class="ml-3">
                  <div class="text-white font-medium">{{ token.symbol }}</div>
                  <div class="text-sm text-gray-400 hidden md:block">
                    {{
                      token.address
                        ? superLong(token.address, 5)
                        : token.assetKey
                    }}
                  </div>
                  <div class="text-sm text-gray-400 block md:hidden">
                    {{
                      token.address
                        ? superLong(token.address, 5)
                        : token.assetKey
                    }}
                  </div>
                </div>
              </div>
              <button
                class="text-red-400 hover:text-red-300 transition-colors"
                @click="removeToken(token)">
                <trash-icon class="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { XMarkIcon, TrashIcon } from '@heroicons/vue/24/outline'
import BaseModal from '@/components/ui/BaseModal.vue'
import { useUserStore, IToken } from '@/stores/user'
import { queryAsset } from '@/service/api/callNabox'
import { superLong } from '@/utils/utils'
import { NULSKey } from '@/constant/contract'

enum LoadingStatus {
  initial = 0,
  loading = 1,
  loaded = 2
}

const props = defineProps<{
  show: boolean
}>()

defineEmits<{
  (e: 'close'): void
}>()

const store = useUserStore()

const tokenAddress = ref('')
const searchResult = ref<IToken[]>([])

watch(
  () => props.show,
  val => {
    if (!val) {
      setTimeout(() => {
        tokenAddress.value = ''
      }, 100)
    }
  }
)

const loading = ref<LoadingStatus>(0)
let timer: number
watch(
  () => tokenAddress.value,
  val => {
    clearTimeout(timer)
    if (val) {
      timer = window.setTimeout(() => {
        loading.value = LoadingStatus.loading
        queryAsset('NULS', val).then(res => {
          loading.value = LoadingStatus.loaded
          searchResult.value = res.map(v => ({
            symbol: v.symbol,
            icon: v.icon,
            decimals: v.decimals,
            address: v.contractAddress,
            assetKey: v.chainId + '-' + v.assetId
          }))
        })
      }, 1000)
    } else {
      loading.value = LoadingStatus.initial
      searchResult.value = []
    }
  }
)

const checkExist = (token: IToken) => {
  return store.tokens.some(v => v.assetKey === token.assetKey)
}

const importToken = (token: IToken) => {
  store.addToken(token)
}

const removeToken = (token: IToken) => {
  store.removeToken(token)
}
</script>
```
