<template>
  <Button
    v-if="!wallet.address"
    variant="primary"
    v-bind="rest"
    :disabled="false"
    @click="connect"
  >
    Connect Wallet
  </Button>
  <Button
    v-else-if="wallet.isWrongChaing"
    variant="primary"
    @click="switchToNULS"
    v-bind="rest"
  >
    Switch To NULS
  </Button>
  <Button v-else v-bind="$attrs">
    <slot name="icon"></slot>
    <slot></slot>
  </Button>
</template>
<script setup lang="ts">
import { useAttrs } from "vue";
import Button from "../ui/Button.vue";
import { useWalletStore } from "@/stores/wallet";
import useProvider from "@/hooks/useProvider";

defineOptions({
  inheritAttrs: false,
});

const attrs = useAttrs();
const { onClick, variant, disabled, ...rest } = attrs;

const wallet = useWalletStore();
const { connect, switchToNULS } = useProvider();
</script>
