import { ref, watch, onMounted, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { IToken, useUserStore } from '@/stores/user'
import { useWalletStore } from '@/stores/wallet'
import { Minus } from '@/utils/utils'
import { NULSKey, MINIMUM_NULS } from '@/constant/contract'

export default function useTokens(pageRoute: '/pools' | '/swap') {
  const route = useRoute()
  const router = useRouter()
  const userStore = useUserStore()
  const walletStore = useWalletStore()

  onMounted(() => {
    const {
      params: { assetKeyA, assetKeyB }
    } = route
    // @ts-ignore
    initToken(assetKeyA, assetKeyB)
  })

  watch(route, val => {
    const {
      params: { assetKeyA, assetKeyB }
    } = val
    // @ts-ignore
    initToken(assetKeyA, assetKeyB)
  })

  const initToken = (assetKeyA: string, assetKeyB: string) => {
    const _tokenA = userStore.tokens.find(v => v.assetKey === assetKeyA)
    const _tokenB = userStore.tokens.find(v => v.assetKey === assetKeyB)
    if (_tokenA && _tokenB) {
      tokenA.value = _tokenA
      tokenB.value = _tokenB
    }
  }

  const tokenA = ref(userStore.defaultTokens[0])
  const tokenB = ref(userStore.defaultTokens[1])
  const tokenAWithBalance = computed(() => {
    if (!walletStore.address) return tokenA.value
    return userStore.tokenWithBalances.find(
      v => v.assetKey === tokenA.value.assetKey
    )!
  })

  const tokenBWithBalance = computed(() => {
    if (!walletStore.address) return tokenB.value
    return userStore.tokenWithBalances.find(
      v => v.assetKey === tokenB.value.assetKey
    )!
  })
  const amountA = ref('')
  const amountB = ref('')
  const tokenType = ref<'A' | 'B'>('A')

  const selectToken = (token: IToken) => {
    const _tokenA = { ...tokenA.value }
    const _tokenB = { ...tokenB.value }
    if (tokenType.value === 'A') {
      if (token.assetKey === tokenB.value.assetKey) {
        tokenB.value = _tokenA
      }
      tokenA.value = token
    } else {
      if (token.assetKey === tokenA.value.assetKey) {
        tokenA.value = _tokenB
      }
      tokenB.value = token
    }
    router.replace(
      `${pageRoute}/${tokenA.value.assetKey}/${tokenB.value.assetKey}`
    )
  }

  const insufficientTokenA = computed(() => {
    if (!amountA.value) return false
    return Minus(tokenAWithBalance.value.balance || '0', amountA.value).toNumber() < 0
  })
  
  const insufficientTokenB = computed(() => {
    if (!amountB.value) return false
    return Minus(tokenBWithBalance.value.balance || '0', amountB.value).toNumber() < 0
  })

  const isNULSLessThanMinimum = computed(() => {
    if (tokenA.value.assetKey === NULSKey) {
      return Minus(amountA.value, MINIMUM_NULS).toNumber() < 0
    } else if (tokenB.value.assetKey === NULSKey) {
      return Minus(amountB.value, MINIMUM_NULS).toNumber() < 0
    } else {
      return false
    }
  })

  return {
    tokenA: tokenAWithBalance,
    tokenB: tokenBWithBalance,
    amountA,
    amountB,
    tokenType,
    selectToken,
    insufficientTokenA,
    insufficientTokenB,
    isNULSLessThanMinimum
  }
}
