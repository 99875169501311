<template>
  <div class="space-y-6">
    <div class="mb-4 md:mb-8">
      <h2 class="text-2xl font-bold text-white mb-2">Stake</h2>
      <p class="text-gray-400">Stake your tokens to earn rewards</p>
    </div>

    <div class="space-y-6">
      <StakeCard
        :stakeType="STAKE_TYPE.STAKE_NSWAP"
        :contract="STAKE_CONTRACT"
        title="Stake NSWAP"
        description="Earn NSWAP rewards by staking NSWAP"
        :total-locked="stake_nswap_earn_nswap.locked"
        :token="NSWAP"
        @stake="handleStake"
        @compound="handleCompound"
        @unstake-all="showUnstakeConfirm = true">
        <template v-slot:stats>
          <div class="flex justify-between items-center text-sm">
            <span class="text-gray-400">Current APR:</span>
            <span class="font-medium"> {{ stake_nswap_earn_nswap.apr }}% </span>
          </div>
          <div class="flex justify-between items-center text-sm">
            <span class="text-gray-400">$NSWAP Staked:</span>
            <span class="font-medium">{{
              stake_nswap_earn_nswap.nswapStaked || '-'
            }}</span>
          </div>

          <div class="flex justify-between items-center text-sm">
            <span class="text-gray-400">$NSWAP Earned:</span>
            <span class="font-medium">{{
              stake_nswap_earn_nswap.nswapEarned || '-'
            }}</span>
          </div>
          <div class="flex justify-between items-center text-sm">
            <span class="text-gray-400">Timelock Unlock Date:</span>
            <span class="font-medium text-right">{{
              stake_nswap_earn_nswap.lockTime || '-'
            }}</span>
          </div>
        </template>
      </StakeCard>
      <StakeCard
        :contract="VNSWAP_CONTRACT"
        :stake-type="STAKE_TYPE.STAKE_NSWAP_EARN_NULS"
        title="Stake NSWAP Earn NULS"
        description="Earn NULS rewards by staking NSWAP"
        :total-locked="stake_nswap_earn_nuls.locked"
        min-stake="1"
        :token="NSWAP"
        @stake="handleStakeNswapEarnNuls"
        @harvest="handleStakeNswapEarnNulsHarvest"
        @get-reward="handleStakeNswapEarnNulsGetReward"
        @unstake-all="handleStakeNswapEarnNulsUnstake">
        <template v-slot:stats>
          <div class="flex justify-between items-center text-sm">
            <span class="text-gray-400">Farmed vNSWAP:</span>
            <span class="font-medium">
              {{ stake_nswap_earn_nuls.farmedVNSWAP || '-' }} vNSWAP
            </span>
          </div>
          <div class="flex justify-between items-center text-sm">
            <span class="text-gray-400">Claimable vNSWAP:</span>
            <span class="font-medium">
              {{ stake_nswap_earn_nuls.claimableVNSWAP || '-' }} vNSWAP
            </span>
          </div>
          <div class="flex justify-between items-center text-sm">
            <span class="text-gray-400">$NSWAP Staked:</span>
            <span class="font-medium">
              {{ stake_nswap_earn_nuls.nswapStaked || '-' }}
            </span>
          </div>
          <div class="flex justify-between items-center text-sm">
            <span class="text-gray-400">$NULS Earned:</span>
            <span class="font-medium">
              {{ stake_nswap_earn_nuls.nulsEarned || '-' }}
            </span>
          </div>
        </template>
      </StakeCard>
    </div>
    <UnstakeModal
      :show="showUnstakeConfirm"
      @close="showUnstakeConfirm = false"
      @unstake="handleUnstakeAll" />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import StakeCard from './StakeCard.vue'
import UnstakeModal from './UnstakeModal.vue'
import { STAKE_CONTRACT, VNSWAP_CONTRACT } from '@/constant/contract'
import { useUserStore } from '@/stores/user'
import { useWalletStore } from '@/stores/wallet'
import useToast from '@/hooks/useToast'
import useStateData, { STAKE_TYPE } from './hooks/useStakeData'
import { LoadingService } from '@/components/Loading/loading-directives'
import { timesDecimals } from '@/utils/utils'
import {
  stakeNswap,
  compoundNswap,
  unstakeNswap,
  stakeNswapEarnNuls,
  stakeNswapEarnNulsHarvest,
  stakeNswapEarnNulsGetReward,
  stakeNswapEarnNulsUnstake
} from '@/utils/nuls/stake'

const walletStore = useWalletStore()
const userStore = useUserStore()
const { toastSuccess, toastError } = useToast()
const { stake_nswap_earn_nswap, stake_nswap_earn_nuls, NSWAP } = useStateData()

const showUnstakeConfirm = ref(false)

const handleStake = async (amount: string) => {
  LoadingService.show()
  try {
    const _amount = timesDecimals(amount, NSWAP.value?.decimals)
    const hash = await stakeNswap(walletStore.address, _amount)
    if (hash) {
      toastSuccess('Success')
      const tx = {
        summary: `Stake ${amount} NSWAP`,
        hash,
        time: new Date().getTime() + '',
        status: 0 as const
      }
      userStore.addTx(tx)
    }
  } catch (e) {
    toastError(e)
  }
  LoadingService.hide()
}

const handleCompound = async () => {
  LoadingService.show()
  try {
    const hash = await compoundNswap(walletStore.address)
    if (hash) {
      toastSuccess('Success')
      const tx = {
        summary: `Compound NSWAP`,
        hash,
        time: new Date().getTime() + '',
        status: 0 as const
      }
      userStore.addTx(tx)
    }
  } catch (e) {
    toastError(e)
  }
  LoadingService.hide()
}

const handleUnstakeAll = async () => {
  LoadingService.show()
  try {
    const hash = await unstakeNswap(walletStore.address)
    if (hash) {
      showUnstakeConfirm.value = false
      toastSuccess('Success')
      const tx = {
        summary: `Unstake NSWAP`,
        hash,
        time: new Date().getTime() + '',
        status: 0 as const
      }
      userStore.addTx(tx)
    }
  } catch (e) {
    toastError(e)
  }
  LoadingService.hide()
}

/*-------------------stake nswap earn nuls-------------------*/
const handleStakeNswapEarnNuls = async (amount: string) => {
  LoadingService.show()
  try {
    const hash = await stakeNswapEarnNuls(
      walletStore.address,
      amount,
      NSWAP.value!.decimals
    )
    if (hash) {
      toastSuccess('Success')
      const tx = {
        summary: `Stake ${amount} NSWAP`,
        hash,
        time: new Date().getTime() + '',
        status: 0 as const
      }
      userStore.addTx(tx)
    }
  } catch (e) {
    toastError(e)
  }
  LoadingService.hide()
}
const handleStakeNswapEarnNulsHarvest = async () => {
  LoadingService.show()
  try {
    const hash = await stakeNswapEarnNulsHarvest(walletStore.address)
    if (hash) {
      toastSuccess('Success')
      const tx = {
        summary: `Harvest`,
        hash,
        time: new Date().getTime() + '',
        status: 0 as const
      }
      userStore.addTx(tx)
    }
  } catch (e) {
    toastError(e)
  }
  LoadingService.hide()
}
const handleStakeNswapEarnNulsGetReward = async () => {
  LoadingService.show()
  try {
    const hash = await stakeNswapEarnNulsGetReward(walletStore.address)
    if (hash) {
      toastSuccess('Success')
      const tx = {
        summary: `Get Reward`,
        hash,
        time: new Date().getTime() + '',
        status: 0 as const
      }
      userStore.addTx(tx)
    }
  } catch (e) {
    toastError(e)
  }
  LoadingService.hide()
}
const handleStakeNswapEarnNulsUnstake = async () => {
  LoadingService.show()
  try {
    const hash = await stakeNswapEarnNulsUnstake(walletStore.address)
    if (hash) {
      toastSuccess('Success')
      const tx = {
        summary: `Withawal NSWAP`,
        hash,
        time: new Date().getTime() + '',
        status: 0 as const
      }
      userStore.addTx(tx)
    }
  } catch (e) {
    toastError(e)
  }
  LoadingService.hide()
}
</script>
